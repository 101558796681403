// be careful!
// generated file with Yii routes with webpack. Don't update lines here!
export const json = {
    "site\/error": {
        "original_route": "usa\/es\/error",
        "route": "mobile-parking-payments\/es\/error",
        "params": []
    },
    "usa\/site\/index": {
        "original_route": "usa\/es",
        "route": "mobile-parking-payments\/es",
        "params": []
    },
    "usa\/sitemap\/lang": {
        "original_route": "usa\/es\/sitemap.xml",
        "route": "mobile-parking-payments\/es\/sitemap.xml",
        "params": []
    },
    "account\/on-boarding": {
        "original_route": "usa\/es\/completar-perfil",
        "route": "mobile-parking-payments\/es\/completar-perfil",
        "params": []
    },
    "on-boarding\/redirect": {
        "original_route": "usa\/es\/on-boarding-ok",
        "route": "mobile-parking-payments\/es\/on-boarding-ok",
        "params": []
    },
    "user\/user\/create": {
        "original_route": "POST usa\/es\/usuario",
        "route": "mobile-parking-payments\/es\/usuario",
        "params": []
    },
    "user\/user\/update": {
        "original_route": "PUT usa\/es\/usuario\/<id:\\d+>",
        "route": "mobile-parking-payments\/es\/usuario\/:id",
        "params": [
            "id"
        ]
    },
    "user\/user\/update-basic-location": {
        "original_route": "PUT usa\/es\/localizacion-basica-usuario\/<id:\\d+>",
        "route": "mobile-parking-payments\/es\/localizacion-basica-usuario\/:id",
        "params": [
            "id"
        ]
    },
    "account\/logout": {
        "original_route": "usa\/es\/desconectar",
        "route": "mobile-parking-payments\/es\/desconectar",
        "params": []
    },
    "user\/account\/request-delete-user": {
        "original_route": "PUT usa\/es\/baja-usuario",
        "route": "mobile-parking-payments\/es\/baja-usuario",
        "params": []
    },
    "user\/account\/delete": {
        "original_route": "usa\/es\/baja-usuario\/<token>",
        "route": "mobile-parking-payments\/es\/baja-usuario\/:token",
        "params": [
            "token"
        ]
    },
    "account\/edit": {
        "original_route": "usa\/es\/mi-cuenta",
        "route": "mobile-parking-payments\/es\/mi-cuenta",
        "params": []
    },
    "account\/user": {
        "original_route": "usa\/es\/mis-datos",
        "route": "mobile-parking-payments\/es\/mis-datos",
        "params": []
    },
    "account\/security": {
        "original_route": "usa\/es\/seguridad",
        "route": "mobile-parking-payments\/es\/seguridad",
        "params": []
    },
    "user\/account\/social-login": {
        "original_route": "usa\/es\/account\/social-login\/<social_network_name>",
        "route": "mobile-parking-payments\/es\/account\/social-login\/:social_network_name",
        "params": [
            "social_network_name"
        ]
    },
    "user\/account\/password-reset": {
        "original_route": "POST usa\/es\/recuperar-contrasena",
        "route": "mobile-parking-payments\/es\/recuperar-contrasena",
        "params": []
    },
    "account\/request-password-reset": {
        "original_route": "usa\/es\/recuperar-contrasena",
        "route": "mobile-parking-payments\/es\/recuperar-contrasena",
        "params": []
    },
    "user\/account\/reset-password-update": {
        "original_route": "POST usa\/es\/cambiar-contrasena\/<token>",
        "route": "mobile-parking-payments\/es\/cambiar-contrasena\/:token",
        "params": [
            "token"
        ]
    },
    "user\/account\/reset-password": {
        "original_route": "usa\/es\/cambiar-contrasena\/<token>",
        "route": "mobile-parking-payments\/es\/cambiar-contrasena\/:token",
        "params": [
            "token"
        ]
    },
    "user\/account\/email-update-request": {
        "original_route": "usa\/es\/solicitar-cambio-email",
        "route": "mobile-parking-payments\/es\/solicitar-cambio-email",
        "params": []
    },
    "account\/update-email": {
        "original_route": "usa\/es\/cambiar-email\/<token>\/<newEmail>",
        "route": "mobile-parking-payments\/es\/cambiar-email\/:token\/:newEmail",
        "params": [
            "token",
            "newEmail"
        ]
    },
    "user\/account\/confirm": {
        "original_route": "usa\/es\/confirmar-cambio-email\/<code>",
        "route": "mobile-parking-payments\/es\/confirmar-cambio-email\/:code",
        "params": [
            "code"
        ]
    },
    "account\/login": {
        "original_route": "usa\/es\/conectar",
        "route": "mobile-parking-payments\/es\/conectar",
        "params": []
    },
    "account\/register": {
        "original_route": "usa\/es\/registro",
        "route": "mobile-parking-payments\/es\/registro",
        "params": []
    },
    "user\/user\/change-password": {
        "original_route": "usa\/es\/cambiar-contrasena",
        "route": "mobile-parking-payments\/es\/cambiar-contrasena",
        "params": []
    },
    "glovie\/preferences": {
        "original_route": "GET usa\/es\/preferencias",
        "route": "mobile-parking-payments\/es\/preferencias",
        "params": []
    },
    "user-preferences\/update": {
        "original_route": "PUT usa\/es\/preferencias\/<id:\\d+>",
        "route": "mobile-parking-payments\/es\/preferencias\/:id",
        "params": [
            "id"
        ]
    },
    "glovie\/privacy": {
        "original_route": "GET usa\/es\/privacidad",
        "route": "mobile-parking-payments\/es\/privacidad",
        "params": []
    },
    "user\/privacy\/create-update": {
        "original_route": "PUT usa\/es\/privacidad",
        "route": "mobile-parking-payments\/es\/privacidad",
        "params": []
    },
    "account\/validate": {
        "original_route": "usa\/es\/validar\/<token>",
        "route": "mobile-parking-payments\/es\/validar\/:token",
        "params": [
            "token"
        ]
    },
    "season-ticket\/contract": {
        "original_route": "GET usa\/es\/abono\/<seasonTicketId:\\d+>\/contratar",
        "route": "mobile-parking-payments\/es\/abono\/:seasonTicketId\/contratar",
        "params": [
            "seasonTicketId"
        ]
    },
    "season-ticket-contract\/show-detail": {
        "original_route": "GET usa\/es\/contratos\/abono\/<id:\\d+>\/detalle",
        "route": "mobile-parking-payments\/es\/contratos\/abono\/:id\/detalle",
        "params": [
            "id"
        ]
    },
    "season-ticket-contract\/show": {
        "original_route": "GET usa\/es\/contratos\/abono\/<id:\\d+>\/ver",
        "route": "mobile-parking-payments\/es\/contratos\/abono\/:id\/ver",
        "params": [
            "id"
        ]
    },
    "season-ticket-contract\/new": {
        "original_route": "GET usa\/es\/contratos\/abono\/<id:\\d+>\/nuevo",
        "route": "mobile-parking-payments\/es\/contratos\/abono\/:id\/nuevo",
        "params": [
            "id"
        ]
    },
    "glovie\/list-actives": {
        "original_route": "GET usa\/es\/guantera\/actuales",
        "route": "mobile-parking-payments\/es\/guantera\/actuales",
        "params": []
    },
    "glovie\/list-inactives": {
        "original_route": "GET usa\/es\/guantera\/pasados\/<page:\\d+>",
        "route": "mobile-parking-payments\/es\/guantera\/pasados\/:page",
        "params": [
            "page"
        ]
    },
    "season-ticket-contract\/create": {
        "original_route": "POST usa\/es\/abono",
        "route": "mobile-parking-payments\/es\/abono",
        "params": []
    },
    "season-ticket-contract\/update-vehicle": {
        "original_route": "PUT usa\/es\/abono\/<contractId:\\d+>\/vehicle\/<vehicleId:\\d+>",
        "route": "mobile-parking-payments\/es\/abono\/:contractId\/vehicle\/:vehicleId",
        "params": [
            "contractId",
            "vehicleId"
        ]
    },
    "api\/season-ticket-contract\/cancel": {
        "original_route": "DELETE usa\/es\/subscription\/<idContract:\\d+>",
        "route": "mobile-parking-payments\/es\/subscription\/:idContract",
        "params": [
            "idContract"
        ]
    },
    "season-ticket-contract\/create-and-pay-with-wallet": {
        "original_route": "POST usa\/es\/pagar-abono\/monedero\/<idWallet:\\d+>",
        "route": "mobile-parking-payments\/es\/pagar-abono\/monedero\/:idWallet",
        "params": [
            "idWallet"
        ]
    },
    "booking\/process": {
        "original_route": "GET usa\/es\/confirmar-reserva\/<idPrebooking:\\d+>",
        "route": "mobile-parking-payments\/es\/confirmar-reserva\/:idPrebooking",
        "params": [
            "idPrebooking"
        ]
    },
    "booking\/show": {
        "original_route": "GET usa\/es\/reserva\/<idBooking:\\d+>\/ver",
        "route": "mobile-parking-payments\/es\/reserva\/:idBooking\/ver",
        "params": [
            "idBooking"
        ]
    },
    "booking\/new": {
        "original_route": "GET usa\/es\/reserva\/<idBooking:\\d+>\/nueva",
        "route": "mobile-parking-payments\/es\/reserva\/:idBooking\/nueva",
        "params": [
            "idBooking"
        ]
    },
    "booking\/cancel": {
        "original_route": "PUT usa\/es\/reserva\/cancelar\/<idBooking:\\d+>",
        "route": "mobile-parking-payments\/es\/reserva\/cancelar\/:idBooking",
        "params": [
            "idBooking"
        ]
    },
    "booking\/pay-with-wallet": {
        "original_route": "PUT usa\/es\/pagar-reserva\/<idBooking:\\d+>\/monedero\/<idWallet:\\d+>",
        "route": "mobile-parking-payments\/es\/pagar-reserva\/:idBooking\/monedero\/:idWallet",
        "params": [
            "idBooking",
            "idWallet"
        ]
    },
    "wallet\/list": {
        "original_route": "GET usa\/es\/monedero\/lista",
        "route": "mobile-parking-payments\/es\/monedero\/lista",
        "params": []
    },
    "wallet\/payment-method": {
        "original_route": "usa\/es\/monedero\/<idWallet:\\d+>\/forma-de-pago",
        "route": "mobile-parking-payments\/es\/monedero\/:idWallet\/forma-de-pago",
        "params": [
            "idWallet"
        ]
    },
    "wallet\/my-wallet": {
        "original_route": "usa\/es\/monedero",
        "route": "mobile-parking-payments\/es\/monedero",
        "params": []
    },
    "wallet\/show": {
        "original_route": "usa\/es\/monedero\/<idWallet:\\d+>\/ver",
        "route": "mobile-parking-payments\/es\/monedero\/:idWallet\/ver",
        "params": [
            "idWallet"
        ]
    },
    "wallet\/beneficiaries": {
        "original_route": "usa\/es\/monedero\/<idWallet:\\d+>\/beneficiarios",
        "route": "mobile-parking-payments\/es\/monedero\/:idWallet\/beneficiarios",
        "params": [
            "idWallet"
        ]
    },
    "glovie\/wallet": {
        "original_route": "usa\/es\/monederos",
        "route": "mobile-parking-payments\/es\/monederos",
        "params": []
    },
    "wallet\/pay-debt": {
        "original_route": "PUT usa\/es\/wallet\/pay-debt",
        "route": "mobile-parking-payments\/es\/wallet\/pay-debt",
        "params": []
    },
    "wallet\/create-deposit": {
        "original_route": "POST usa\/es\/monedero\/recarga",
        "route": "mobile-parking-payments\/es\/monedero\/recarga",
        "params": []
    },
    "wallet\/associate-credit-card": {
        "original_route": "POST usa\/es\/monedero\/<idWallet:\\d+>\/asociar-tarjeta\/<idCreditCard:\\d+>",
        "route": "mobile-parking-payments\/es\/monedero\/:idWallet\/asociar-tarjeta\/:idCreditCard",
        "params": [
            "idWallet",
            "idCreditCard"
        ]
    },
    "wallet-preferences\/index": {
        "original_route": "GET usa\/es\/preferencias-monedero",
        "route": "mobile-parking-payments\/es\/preferencias-monedero",
        "params": []
    },
    "wallet-preferences\/update": {
        "original_route": "PUT usa\/es\/preferencias-monedero\/<id:\\d+>",
        "route": "mobile-parking-payments\/es\/preferencias-monedero\/:id",
        "params": [
            "id"
        ]
    },
    "credit-card\/new": {
        "original_route": "POST usa\/es\/tarjeta-credito",
        "route": "mobile-parking-payments\/es\/tarjeta-credito",
        "params": []
    },
    "credit-card\/delete": {
        "original_route": "DELETE usa\/es\/tarjeta-credito\/<id:\\d+>",
        "route": "mobile-parking-payments\/es\/tarjeta-credito\/:id",
        "params": [
            "id"
        ]
    },
    "credit-card\/create": {
        "original_route": "POST usa\/es\/monedero\/<idWallet:\\d+>\/tarjeta-credito",
        "route": "mobile-parking-payments\/es\/monedero\/:idWallet\/tarjeta-credito",
        "params": [
            "idWallet"
        ]
    },
    "credit-card\/list-by-country": {
        "original_route": "GET usa\/es\/tarjeta-credito\/lista\/<countryId:\\d+>",
        "route": "mobile-parking-payments\/es\/tarjeta-credito\/lista\/:countryId",
        "params": [
            "countryId"
        ]
    },
    "glovie\/vehicles": {
        "original_route": "usa\/es\/mis-vehiculos",
        "route": "mobile-parking-payments\/es\/mis-vehiculos",
        "params": []
    },
    "glovie\/vehicles-page": {
        "original_route": "usa\/es\/mis-vehiculos\/<page:\\d+>",
        "route": "mobile-parking-payments\/es\/mis-vehiculos\/:page",
        "params": [
            "page"
        ]
    },
    "glovie\/trailers": {
        "original_route": "usa\/es\/mis-remolques",
        "route": "mobile-parking-payments\/es\/mis-remolques",
        "params": []
    },
    "glovie\/trailers-page": {
        "original_route": "usa\/es\/mis-remolques\/<page:\\d+>",
        "route": "mobile-parking-payments\/es\/mis-remolques\/:page",
        "params": [
            "page"
        ]
    },
    "vehicle-brand\/vehicle-models": {
        "original_route": "GET usa\/es\/vehiculo-marca\/<brandId:\\d+>\/modelos",
        "route": "mobile-parking-payments\/es\/vehiculo-marca\/:brandId\/modelos",
        "params": [
            "brandId"
        ]
    },
    "vehicle\/index": {
        "original_route": "GET usa\/es\/vehiculo",
        "route": "mobile-parking-payments\/es\/vehiculo",
        "params": []
    },
    "vehicle\/view": {
        "original_route": "GET usa\/es\/vehiculo\/<vehicleId:\\d+>",
        "route": "mobile-parking-payments\/es\/vehiculo\/:vehicleId",
        "params": [
            "vehicleId"
        ]
    },
    "vehicle\/delete": {
        "original_route": "DELETE usa\/es\/vehiculo\/<vehicleId:\\d+>",
        "route": "mobile-parking-payments\/es\/vehiculo\/:vehicleId",
        "params": [
            "vehicleId"
        ]
    },
    "vehicle\/create": {
        "original_route": "POST usa\/es\/vehiculo",
        "route": "mobile-parking-payments\/es\/vehiculo",
        "params": []
    },
    "vehicle\/update": {
        "original_route": "PUT usa\/es\/vehiculo\/<vehicleId:\\d+>",
        "route": "mobile-parking-payments\/es\/vehiculo\/:vehicleId",
        "params": [
            "vehicleId"
        ]
    },
    "offered-parking-pass\/contract": {
        "original_route": "GET usa\/es\/parking\/<idParking:\\d+>\/bono\/<id:\\d+>",
        "route": "mobile-parking-payments\/es\/parking\/:idParking\/bono\/:id",
        "params": [
            "idParking",
            "id"
        ]
    },
    "booked-parking-pass\/new": {
        "original_route": "GET usa\/es\/bono\/<id:\\d+>\/nuevo",
        "route": "mobile-parking-payments\/es\/bono\/:id\/nuevo",
        "params": [
            "id"
        ]
    },
    "booked-parking-pass\/show": {
        "original_route": "GET usa\/es\/bono\/<id:\\d+>\/ver",
        "route": "mobile-parking-payments\/es\/bono\/:id\/ver",
        "params": [
            "id"
        ]
    },
    "merchant\/index": {
        "original_route": "usa\/es\/comerciantes\/mi-cuenta",
        "route": "mobile-parking-payments\/es\/comerciantes\/mi-cuenta",
        "params": []
    },
    "merchant\/shop": {
        "original_route": "usa\/es\/comerciantes\/tienda",
        "route": "mobile-parking-payments\/es\/comerciantes\/tienda",
        "params": []
    },
    "merchant\/shop-step": {
        "original_route": "usa\/es\/comerciantes\/tienda\/paso<id:\\d+>",
        "route": "mobile-parking-payments\/es\/comerciantes\/tienda\/paso:id",
        "params": [
            "id"
        ]
    },
    "coupon-order\/pdf": {
        "original_route": "usa\/es\/comerciantes\/pedido\/<id:\\d+>.pdf",
        "route": "mobile-parking-payments\/es\/comerciantes\/pedido\/:id.pdf",
        "params": [
            "id"
        ]
    },
    "merchant\/order": {
        "original_route": "usa\/es\/comerciantes\/pedido\/<id:\\d+>\/detalle",
        "route": "mobile-parking-payments\/es\/comerciantes\/pedido\/:id\/detalle",
        "params": [
            "id"
        ]
    },
    "merchant\/sample-coupon": {
        "original_route": "usa\/es\/merchant\/sample-coupon",
        "route": "mobile-parking-payments\/es\/merchant\/sample-coupon",
        "params": []
    },
    "user-preferences\/auto-deposit": {
        "original_route": "usa\/es\/deposito",
        "route": "mobile-parking-payments\/es\/deposito",
        "params": []
    },
    "invoice\/pdf": {
        "original_route": "GET usa\/es\/facturacion\/facturas\/<invoiceUid>.pdf",
        "route": "mobile-parking-payments\/es\/facturacion\/facturas\/:invoiceUid.pdf",
        "params": [
            "invoiceUid"
        ]
    },
    "invoice\/xlsx": {
        "original_route": "GET usa\/es\/facturacion\/facturas\/<invoiceUid>.xlsx",
        "route": "mobile-parking-payments\/es\/facturacion\/facturas\/:invoiceUid.xlsx",
        "params": [
            "invoiceUid"
        ]
    },
    "invoice\/view-xlsx": {
        "original_route": "GET usa\/es\/descarga-facturacion\/<invoiceUid>.xlsx",
        "route": "mobile-parking-payments\/es\/descarga-facturacion\/:invoiceUid.xlsx",
        "params": [
            "invoiceUid"
        ]
    },
    "invoice\/list": {
        "original_route": "GET usa\/es\/facturacion\/facturas",
        "route": "mobile-parking-payments\/es\/facturacion\/facturas",
        "params": []
    },
    "invoice\/page": {
        "original_route": "GET usa\/es\/facturacion\/facturas\/<page:\\d+>",
        "route": "mobile-parking-payments\/es\/facturacion\/facturas\/:page",
        "params": [
            "page"
        ]
    },
    "invoice\/update": {
        "original_route": "PUT usa\/es\/facturacion\/datos",
        "route": "mobile-parking-payments\/es\/facturacion\/datos",
        "params": []
    },
    "usa\/site\/landing-enterprise": {
        "original_route": "usa\/es\/empresas",
        "route": "mobile-parking-payments\/es\/empresas",
        "params": []
    },
    "parking\/list": {
        "original_route": "usa\/es\/buscar-parking",
        "route": "mobile-parking-payments\/es\/buscar-parking",
        "params": []
    },
    "parking\/show": {
        "original_route": "usa\/es\/<province>\/<slug>\/<idParking:\\d+>",
        "route": "mobile-parking-payments\/es\/:province\/:slug\/:idParking",
        "params": [
            "province",
            "slug",
            "idParking"
        ]
    },
    "site\/terms": {
        "original_route": "usa\/es\/terminos-legales",
        "route": "mobile-parking-payments\/es\/terminos-legales",
        "params": []
    },
    "site\/terms-cookies": {
        "original_route": "usa\/es\/politica-privacidad-cookies",
        "route": "mobile-parking-payments\/es\/politica-privacidad-cookies",
        "params": []
    },
    "user\/account\/unsubscribe-from-commercials": {
        "original_route": "usa\/es\/baja-correo-comercial\/<email>",
        "route": "mobile-parking-payments\/es\/baja-correo-comercial\/:email",
        "params": [
            "email"
        ]
    },
    "site\/landing-merchant": {
        "original_route": "usa\/es\/comerciantes",
        "route": "mobile-parking-payments\/es\/comerciantes",
        "params": []
    },
    "site\/landing-merchant-how-it-works": {
        "original_route": "usa\/es\/canjear-cupones",
        "route": "mobile-parking-payments\/es\/canjear-cupones",
        "params": []
    },
    "poi\/show": {
        "original_route": "usa\/es\/aparcar-en-<townSlug>\/parking-<poiSlug>",
        "route": "mobile-parking-payments\/es\/aparcar-en-:townSlug\/parking-:poiSlug",
        "params": [
            "townSlug",
            "poiSlug"
        ]
    },
    "poi\/show-events": {
        "original_route": "usa\/es\/eventos-en-<poiId>\/<month:\\d+>",
        "route": "mobile-parking-payments\/es\/eventos-en-:poiId\/:month",
        "params": [
            "poiId",
            "month"
        ]
    },
    "town\/show": {
        "original_route": "usa\/es\/aparcar-en-<slug>",
        "route": "mobile-parking-payments\/es\/aparcar-en-:slug",
        "params": [
            "slug"
        ]
    },
    "fine-portal\/index": {
        "original_route": "usa\/es\/fines-portal\/<townId>",
        "route": "mobile-parking-payments\/es\/fines-portal\/:townId",
        "params": [
            "townId"
        ]
    },
    "fine-portal\/fine": {
        "original_route": "usa\/es\/parkontrol\/<townId>\/fine\/<bulletinNumber:\\d+>",
        "route": "mobile-parking-payments\/es\/parkontrol\/:townId\/fine\/:bulletinNumber",
        "params": [
            "townId",
            "bulletinNumber"
        ]
    },
    "fine-portal\/slug-with-bulletin": {
        "original_route": "usa\/es\/parkontrol-<slug>\/<bulletinNumber:\\d+>",
        "route": "mobile-parking-payments\/es\/parkontrol-:slug\/:bulletinNumber",
        "params": [
            "slug",
            "bulletinNumber"
        ]
    },
    "fine-portal\/slug": {
        "original_route": "usa\/es\/parkontrol-<slug>",
        "route": "mobile-parking-payments\/es\/parkontrol-:slug",
        "params": [
            "slug"
        ]
    },
    "fine-portal\/portal": {
        "original_route": "usa\/es\/parkontrol",
        "route": "mobile-parking-payments\/es\/parkontrol",
        "params": []
    },
    "fine-portal\/portal-with-town": {
        "original_route": "usa\/es\/parkontrol\/<townId>",
        "route": "mobile-parking-payments\/es\/parkontrol\/:townId",
        "params": [
            "townId"
        ]
    },
    "wallet-corporate-plates-whitelist\/download": {
        "original_route": "usa\/es\/plates-whitelist\/<walletId:\\d+>\/download",
        "route": "mobile-parking-payments\/es\/plates-whitelist\/:walletId\/download",
        "params": [
            "walletId"
        ]
    },
    "api\/wallet-corporate\/download-file": {
        "original_route": "GET usa\/es\/wallets\/<walletId:\\d+>\/download-movements-csv\/<file>",
        "route": "mobile-parking-payments\/es\/wallets\/:walletId\/download-movements-csv\/:file",
        "params": [
            "walletId",
            "file"
        ]
    }
};