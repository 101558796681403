// be careful!
// generated file with Yii routes with webpack. Don't update lines here!
export const json = {
    "bra\/site\/index": {
        "original_route": "bra",
        "route": "mobile-parking-payments\/bra",
        "params": []
    },
    "site\/error": {
        "original_route": "bra\/error",
        "route": "mobile-parking-payments\/bra\/error",
        "params": []
    },
    "sitemap\/index": {
        "original_route": "bra\/sitemap.xml",
        "route": "mobile-parking-payments\/bra\/sitemap.xml",
        "params": []
    },
    "bra\/site\/home-redirect": {
        "original_route": "bra\/pt-br",
        "route": "mobile-parking-payments\/bra\/pt-br",
        "params": []
    },
    "bra\/sitemap\/lang": {
        "original_route": "bra\/pt-br\/sitemap.xml",
        "route": "mobile-parking-payments\/bra\/pt-br\/sitemap.xml",
        "params": []
    },
    "site\/terms": {
        "original_route": "bra\/termos-legais",
        "route": "mobile-parking-payments\/bra\/termos-legais",
        "params": []
    },
    "site\/terms-cookies": {
        "original_route": "bra\/privacidade-cookies",
        "route": "mobile-parking-payments\/bra\/privacidade-cookies",
        "params": []
    },
    "account\/on-boarding": {
        "original_route": "bra\/complete-profile",
        "route": "mobile-parking-payments\/bra\/complete-profile",
        "params": []
    },
    "on-boarding\/redirect": {
        "original_route": "bra\/on-boarding-ok",
        "route": "mobile-parking-payments\/bra\/on-boarding-ok",
        "params": []
    },
    "user\/user\/create": {
        "original_route": "POST bra\/user",
        "route": "mobile-parking-payments\/bra\/user",
        "params": []
    },
    "user\/user\/update": {
        "original_route": "PUT bra\/user\/<id:\\d+>",
        "route": "mobile-parking-payments\/bra\/user\/:id",
        "params": [
            "id"
        ]
    },
    "user\/user\/update-basic-location": {
        "original_route": "PUT bra\/user-basic\/<id:\\d+>",
        "route": "mobile-parking-payments\/bra\/user-basic\/:id",
        "params": [
            "id"
        ]
    },
    "account\/logout": {
        "original_route": "bra\/desconectar",
        "route": "mobile-parking-payments\/bra\/desconectar",
        "params": []
    },
    "user\/account\/request-delete-user": {
        "original_route": "PUT bra\/cancelar-assinatura",
        "route": "mobile-parking-payments\/bra\/cancelar-assinatura",
        "params": []
    },
    "user\/account\/delete": {
        "original_route": "bra\/cancelar-assinatura\/<token>",
        "route": "mobile-parking-payments\/bra\/cancelar-assinatura\/:token",
        "params": [
            "token"
        ]
    },
    "account\/edit": {
        "original_route": "bra\/minha-conta",
        "route": "mobile-parking-payments\/bra\/minha-conta",
        "params": []
    },
    "account\/user": {
        "original_route": "bra\/minha-info",
        "route": "mobile-parking-payments\/bra\/minha-info",
        "params": []
    },
    "account\/security": {
        "original_route": "bra\/seguranca",
        "route": "mobile-parking-payments\/bra\/seguranca",
        "params": []
    },
    "user\/account\/social-login": {
        "original_route": "bra\/conta\/social-login\/<social_network_name>",
        "route": "mobile-parking-payments\/bra\/conta\/social-login\/:social_network_name",
        "params": [
            "social_network_name"
        ]
    },
    "user\/account\/password-reset": {
        "original_route": "POST bra\/redefinir-senha",
        "route": "mobile-parking-payments\/bra\/redefinir-senha",
        "params": []
    },
    "account\/request-password-reset": {
        "original_route": "bra\/redefinir-senha",
        "route": "mobile-parking-payments\/bra\/redefinir-senha",
        "params": []
    },
    "user\/account\/reset-password-update": {
        "original_route": "POST bra\/redefinir-senha\/<token>",
        "route": "mobile-parking-payments\/bra\/redefinir-senha\/:token",
        "params": [
            "token"
        ]
    },
    "user\/account\/reset-password": {
        "original_route": "bra\/redefinir-senha\/<token>",
        "route": "mobile-parking-payments\/bra\/redefinir-senha\/:token",
        "params": [
            "token"
        ]
    },
    "user\/account\/email-update-request": {
        "original_route": "bra\/email-change-request",
        "route": "mobile-parking-payments\/bra\/email-change-request",
        "params": []
    },
    "account\/update-email": {
        "original_route": "bra\/mudar-email\/<token>\/<newEmail>",
        "route": "mobile-parking-payments\/bra\/mudar-email\/:token\/:newEmail",
        "params": [
            "token",
            "newEmail"
        ]
    },
    "user\/account\/confirm": {
        "original_route": "bra\/mudar-email-confirmacao\/<code>",
        "route": "mobile-parking-payments\/bra\/mudar-email-confirmacao\/:code",
        "params": [
            "code"
        ]
    },
    "account\/login": {
        "original_route": "bra\/fazer-login",
        "route": "mobile-parking-payments\/bra\/fazer-login",
        "params": []
    },
    "account\/register": {
        "original_route": "bra\/criar-conta",
        "route": "mobile-parking-payments\/bra\/criar-conta",
        "params": []
    },
    "user\/user\/change-password": {
        "original_route": "bra\/mudar-password",
        "route": "mobile-parking-payments\/bra\/mudar-password",
        "params": []
    },
    "glovie\/preferences": {
        "original_route": "GET bra\/preferencias",
        "route": "mobile-parking-payments\/bra\/preferencias",
        "params": []
    },
    "user-preferences\/update": {
        "original_route": "PUT bra\/preferencias\/<id:\\d+>",
        "route": "mobile-parking-payments\/bra\/preferencias\/:id",
        "params": [
            "id"
        ]
    },
    "glovie\/privacy": {
        "original_route": "GET bra\/privacy",
        "route": "mobile-parking-payments\/bra\/privacy",
        "params": []
    },
    "user\/privacy\/create-update": {
        "original_route": "PUT bra\/privacy",
        "route": "mobile-parking-payments\/bra\/privacy",
        "params": []
    },
    "account\/validate": {
        "original_route": "bra\/validar\/<token>",
        "route": "mobile-parking-payments\/bra\/validar\/:token",
        "params": [
            "token"
        ]
    },
    "user\/account\/unsubscribe-from-commercials": {
        "original_route": "bra\/cancelar-comerciais\/<email>",
        "route": "mobile-parking-payments\/bra\/cancelar-comerciais\/:email",
        "params": [
            "email"
        ]
    },
    "wallet\/list": {
        "original_route": "GET bra\/carteira\/lista",
        "route": "mobile-parking-payments\/bra\/carteira\/lista",
        "params": []
    },
    "wallet\/payment-method": {
        "original_route": "bra\/carteira\/<idWallet:\\d+>\/pagamento",
        "route": "mobile-parking-payments\/bra\/carteira\/:idWallet\/pagamento",
        "params": [
            "idWallet"
        ]
    },
    "wallet\/data": {
        "original_route": "bra\/carteira\/<idWallet:\\d+>\/dados",
        "route": "mobile-parking-payments\/bra\/carteira\/:idWallet\/dados",
        "params": [
            "idWallet"
        ]
    },
    "wallet\/beneficiaries": {
        "original_route": "bra\/carteira\/<idWallet:\\d+>\/beneficiarios",
        "route": "mobile-parking-payments\/bra\/carteira\/:idWallet\/beneficiarios",
        "params": [
            "idWallet"
        ]
    },
    "wallet\/my-wallet": {
        "original_route": "bra\/carteira",
        "route": "mobile-parking-payments\/bra\/carteira",
        "params": []
    },
    "wallet\/show": {
        "original_route": "bra\/carteira\/<idWallet:\\d+>\/show",
        "route": "mobile-parking-payments\/bra\/carteira\/:idWallet\/show",
        "params": [
            "idWallet"
        ]
    },
    "glovie\/wallet": {
        "original_route": "bra\/carteiras",
        "route": "mobile-parking-payments\/bra\/carteiras",
        "params": []
    },
    "wallet\/pay-debt": {
        "original_route": "PUT bra\/carteira\/pagar-dividas",
        "route": "mobile-parking-payments\/bra\/carteira\/pagar-dividas",
        "params": []
    },
    "wallet\/create-deposit": {
        "original_route": "POST bra\/carteira\/deposito",
        "route": "mobile-parking-payments\/bra\/carteira\/deposito",
        "params": []
    },
    "wallet\/associate-credit-card": {
        "original_route": "POST bra\/carteira\/<idWallet:\\d+>\/cartao-associado\/<idCreditCard:\\d+>",
        "route": "mobile-parking-payments\/bra\/carteira\/:idWallet\/cartao-associado\/:idCreditCard",
        "params": [
            "idWallet",
            "idCreditCard"
        ]
    },
    "wallet-preferences\/index": {
        "original_route": "GET bra\/carteira-preferencias",
        "route": "mobile-parking-payments\/bra\/carteira-preferencias",
        "params": []
    },
    "wallet-preferences\/update": {
        "original_route": "PUT bra\/carteira-preferencias\/<id:\\d+>",
        "route": "mobile-parking-payments\/bra\/carteira-preferencias\/:id",
        "params": [
            "id"
        ]
    },
    "credit-card\/new": {
        "original_route": "POST bra\/credit-card",
        "route": "mobile-parking-payments\/bra\/credit-card",
        "params": []
    },
    "credit-card\/delete": {
        "original_route": "DELETE bra\/credit-card\/<id:\\d+>",
        "route": "mobile-parking-payments\/bra\/credit-card\/:id",
        "params": [
            "id"
        ]
    },
    "credit-card\/create": {
        "original_route": "POST bra\/wallet\/<idWallet:\\d+>\/credit-card",
        "route": "mobile-parking-payments\/bra\/wallet\/:idWallet\/credit-card",
        "params": [
            "idWallet"
        ]
    },
    "credit-card\/list-by-country": {
        "original_route": "GET bra\/credit-card\/list\/<countryId:\\d+>",
        "route": "mobile-parking-payments\/bra\/credit-card\/list\/:countryId",
        "params": [
            "countryId"
        ]
    },
    "glovie\/vehicles": {
        "original_route": "bra\/meus-veiculos",
        "route": "mobile-parking-payments\/bra\/meus-veiculos",
        "params": []
    },
    "glovie\/vehicles-page": {
        "original_route": "bra\/meus-veiculos\/<page:\\d+>",
        "route": "mobile-parking-payments\/bra\/meus-veiculos\/:page",
        "params": [
            "page"
        ]
    },
    "vehicle-brand\/vehicle-models": {
        "original_route": "GET bra\/veiculo-brand\/<brandId:\\d+>\/models",
        "route": "mobile-parking-payments\/bra\/veiculo-brand\/:brandId\/models",
        "params": [
            "brandId"
        ]
    },
    "glovie\/trailers": {
        "original_route": "bra\/meus-trailers",
        "route": "mobile-parking-payments\/bra\/meus-trailers",
        "params": []
    },
    "glovie\/trailers-page": {
        "original_route": "bra\/meus-trailers\/<page:\\d+>",
        "route": "mobile-parking-payments\/bra\/meus-trailers\/:page",
        "params": [
            "page"
        ]
    },
    "vehicle\/index": {
        "original_route": "GET bra\/veiculo",
        "route": "mobile-parking-payments\/bra\/veiculo",
        "params": []
    },
    "vehicle\/view": {
        "original_route": "GET bra\/veiculo\/<vehicleid:\\d+>",
        "route": "mobile-parking-payments\/bra\/veiculo\/:vehicleid",
        "params": [
            "vehicleid"
        ]
    },
    "vehicle\/delete": {
        "original_route": "DELETE bra\/veiculo\/<vehicleId:\\d+>",
        "route": "mobile-parking-payments\/bra\/veiculo\/:vehicleId",
        "params": [
            "vehicleId"
        ]
    },
    "vehicle\/create": {
        "original_route": "POST bra\/veiculo",
        "route": "mobile-parking-payments\/bra\/veiculo",
        "params": []
    },
    "vehicle\/update": {
        "original_route": "PUT bra\/veiculo\/<vehicleId:\\d+>",
        "route": "mobile-parking-payments\/bra\/veiculo\/:vehicleId",
        "params": [
            "vehicleId"
        ]
    },
    "user-preferences\/auto-deposit": {
        "original_route": "bra\/auto-deposit",
        "route": "mobile-parking-payments\/bra\/auto-deposit",
        "params": []
    },
    "invoice\/pdf": {
        "original_route": "GET bra\/faturamento\/faturas\/<invoiceUid>.pdf",
        "route": "mobile-parking-payments\/bra\/faturamento\/faturas\/:invoiceUid.pdf",
        "params": [
            "invoiceUid"
        ]
    },
    "invoice\/xlsx": {
        "original_route": "GET bra\/faturamento\/faturas\/<invoiceUid>.xlsx",
        "route": "mobile-parking-payments\/bra\/faturamento\/faturas\/:invoiceUid.xlsx",
        "params": [
            "invoiceUid"
        ]
    },
    "invoice\/view-xlsx": {
        "original_route": "GET bra\/download-fatura\/<invoiceUid>.xlsx",
        "route": "mobile-parking-payments\/bra\/download-fatura\/:invoiceUid.xlsx",
        "params": [
            "invoiceUid"
        ]
    },
    "invoice\/list": {
        "original_route": "GET bra\/faturamento\/faturas",
        "route": "mobile-parking-payments\/bra\/faturamento\/faturas",
        "params": []
    },
    "invoice\/page": {
        "original_route": "GET bra\/faturamento\/faturas\/<page:\\d+>",
        "route": "mobile-parking-payments\/bra\/faturamento\/faturas\/:page",
        "params": [
            "page"
        ]
    },
    "invoice\/update": {
        "original_route": "PUT bra\/faturamento\/data",
        "route": "mobile-parking-payments\/bra\/faturamento\/data",
        "params": []
    },
    "kiosk\/reset-password": {
        "original_route": "bra\/kiosko\/redefinir-senha\/<token>",
        "route": "mobile-parking-payments\/bra\/kiosko\/redefinir-senha\/:token",
        "params": [
            "token"
        ]
    },
    "ser-ticket\/show-detail": {
        "original_route": "GET bra\/tiquete\/<idSERTicket:\\w+>\/info",
        "route": "mobile-parking-payments\/bra\/tiquete\/:idSERTicket\/info",
        "params": [
            "idSERTicket"
        ]
    },
    "ser-ticket\/send-email": {
        "original_route": "GET bra\/tiquete\/<idSERTicket:\\w+>\/enviar-email",
        "route": "mobile-parking-payments\/bra\/tiquete\/:idSERTicket\/enviar-email",
        "params": [
            "idSERTicket"
        ]
    },
    "glovie\/list-actives": {
        "original_route": "GET bra\/guantera\/actuales",
        "route": "mobile-parking-payments\/bra\/guantera\/actuales",
        "params": []
    },
    "glovie\/list-inactives": {
        "original_route": "GET bra\/guantera\/pasados\/<page:\\d+>",
        "route": "mobile-parking-payments\/bra\/guantera\/pasados\/:page",
        "params": [
            "page"
        ]
    },
    "ser-ticket\/download-pdf": {
        "original_route": "GET bra\/tiquete\/<idSERTicket:\\w+>.pdf",
        "route": "mobile-parking-payments\/bra\/tiquete\/:idSERTicket.pdf",
        "params": [
            "idSERTicket"
        ]
    },
    "ser-ticket\/create": {
        "original_route": "POST bra\/tiquete\/tirar",
        "route": "mobile-parking-payments\/bra\/tiquete\/tirar",
        "params": []
    },
    "ser-ticket\/unpark": {
        "original_route": "POST bra\/tiquete\/<id>\/unpark",
        "route": "mobile-parking-payments\/bra\/tiquete\/:id\/unpark",
        "params": [
            "id"
        ]
    },
    "ser-ticket\/check-unpark": {
        "original_route": "GET bra\/tiquete\/<id>\/unpark",
        "route": "mobile-parking-payments\/bra\/tiquete\/:id\/unpark",
        "params": [
            "id"
        ]
    },
    "ser-ticket\/show": {
        "original_route": "bra\/tiquete\/<id>\/detalhe",
        "route": "mobile-parking-payments\/bra\/tiquete\/:id\/detalhe",
        "params": [
            "id"
        ]
    },
    "ser-ticket\/new": {
        "original_route": "bra\/tiquete\/<id>\/novo",
        "route": "mobile-parking-payments\/bra\/tiquete\/:id\/novo",
        "params": [
            "id"
        ]
    },
    "ser-ticket\/create-and-pay-with-wallet": {
        "original_route": "POST bra\/pagar-tiquete\/carteira\/<idWallet:\\d+>",
        "route": "mobile-parking-payments\/bra\/pagar-tiquete\/carteira\/:idWallet",
        "params": [
            "idWallet"
        ]
    },
    "ser-ticket\/pay": {
        "original_route": "bra\/estacionar-em-<slug>\/conseguir-ticket",
        "route": "mobile-parking-payments\/bra\/estacionar-em-:slug\/conseguir-ticket",
        "params": [
            "slug"
        ]
    },
    "ser-steps\/index": {
        "original_route": "GET bra\/steps\/zone\/<zoneId:\\w+>\/rate\/<rateId:\\w+>\/vehicle\/<vehicleId:\\d+>",
        "route": "mobile-parking-payments\/bra\/steps\/zone\/:zoneId\/rate\/:rateId\/vehicle\/:vehicleId",
        "params": [
            "zoneId",
            "rateId",
            "vehicleId"
        ]
    },
    "town\/show": {
        "original_route": "bra\/estacionar-em-<slug>",
        "route": "mobile-parking-payments\/bra\/estacionar-em-:slug",
        "params": [
            "slug"
        ]
    },
    "poi\/show": {
        "original_route": "bra\/estacionar-em-<townSlug>\/parking-<poiSlug>\/eventos",
        "route": "mobile-parking-payments\/bra\/estacionar-em-:townSlug\/parking-:poiSlug\/eventos",
        "params": [
            "townSlug",
            "poiSlug"
        ]
    },
    "wallet-corporate-plates-whitelist\/download": {
        "original_route": "bra\/plates-whitelist\/<walletId:\\d+>\/download",
        "route": "mobile-parking-payments\/bra\/plates-whitelist\/:walletId\/download",
        "params": [
            "walletId"
        ]
    },
    "api\/wallet-corporate\/download-file": {
        "original_route": "GET bra\/wallets\/<walletId:\\d+>\/download-movements-csv\/<file>",
        "route": "mobile-parking-payments\/bra\/wallets\/:walletId\/download-movements-csv\/:file",
        "params": [
            "walletId",
            "file"
        ]
    }
};