// be careful!
// generated file with Yii routes with webpack. Don't update lines here!
export const json = {
    "col\/site\/index": {
        "original_route": "col",
        "route": "mobile-parking-payments\/col",
        "params": []
    },
    "sitemap\/index": {
        "original_route": "col\/sitemap.xml",
        "route": "mobile-parking-payments\/col\/sitemap.xml",
        "params": []
    },
    "col\/site\/home-redirect": {
        "original_route": "col\/es-co",
        "route": "mobile-parking-payments\/col\/es-co",
        "params": []
    },
    "col\/sitemap\/lang": {
        "original_route": "col\/es-co\/sitemap.xml",
        "route": "mobile-parking-payments\/col\/es-co\/sitemap.xml",
        "params": []
    },
    "site\/error": {
        "original_route": "col\/error",
        "route": "mobile-parking-payments\/col\/error",
        "params": []
    },
    "site\/terms": {
        "original_route": "col\/terminos-legales",
        "route": "mobile-parking-payments\/col\/terminos-legales",
        "params": []
    },
    "site\/terms-cookies": {
        "original_route": "col\/politica-privacidad-cookies",
        "route": "mobile-parking-payments\/col\/politica-privacidad-cookies",
        "params": []
    },
    "user\/account\/password-reset": {
        "original_route": "POST col\/recuperar-contrasena",
        "route": "mobile-parking-payments\/col\/recuperar-contrasena",
        "params": []
    },
    "account\/request-password-reset": {
        "original_route": "col\/recuperar-contrasena",
        "route": "mobile-parking-payments\/col\/recuperar-contrasena",
        "params": []
    },
    "user\/account\/reset-password-update": {
        "original_route": "POST col\/cambiar-contrasena\/<token>",
        "route": "mobile-parking-payments\/col\/cambiar-contrasena\/:token",
        "params": [
            "token"
        ]
    },
    "user\/account\/reset-password": {
        "original_route": "col\/cambiar-contrasena\/<token>",
        "route": "mobile-parking-payments\/col\/cambiar-contrasena\/:token",
        "params": [
            "token"
        ]
    },
    "user\/account\/email-update-request": {
        "original_route": "col\/email-change-request",
        "route": "mobile-parking-payments\/col\/email-change-request",
        "params": []
    },
    "account\/update-email": {
        "original_route": "col\/cambiar-email\/<token>\/<newEmail>",
        "route": "mobile-parking-payments\/col\/cambiar-email\/:token\/:newEmail",
        "params": [
            "token",
            "newEmail"
        ]
    },
    "user\/account\/confirm": {
        "original_route": "col\/cambiar-email-confirmacion\/<code>",
        "route": "mobile-parking-payments\/col\/cambiar-email-confirmacion\/:code",
        "params": [
            "code"
        ]
    },
    "account\/validate": {
        "original_route": "col\/validar\/<token>",
        "route": "mobile-parking-payments\/col\/validar\/:token",
        "params": [
            "token"
        ]
    },
    "user\/account\/request-delete-user": {
        "original_route": "PUT col\/baja-usuario",
        "route": "mobile-parking-payments\/col\/baja-usuario",
        "params": []
    },
    "user\/account\/delete": {
        "original_route": "col\/baja-usuario\/<token>",
        "route": "mobile-parking-payments\/col\/baja-usuario\/:token",
        "params": [
            "token"
        ]
    }
};