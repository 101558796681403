// be careful!
// generated file with Yii routes with webpack. Don't update lines here!
export const json = {
    "ser-steps\/index": {
        "original_route": "GET steps\/zone\/<zoneId:\\w+>\/rate\/<rateId:\\w+>\/vehicle\/<vehicleId:\\d+>",
        "route": "steps\/zone\/:zoneId\/rate\/:rateId\/vehicle\/:vehicleId",
        "params": [
            "zoneId",
            "rateId",
            "vehicleId"
        ]
    },
    "api\/airports\/index": {
        "original_route": "GET api\/airports",
        "route": "api\/airports",
        "params": []
    },
    "api\/airports\/view": {
        "original_route": "GET api\/airports\/<idAirport>",
        "route": "api\/airports\/:idAirport",
        "params": [
            "idAirport"
        ]
    },
    "api\/airports\/parkings": {
        "original_route": "GET api\/airports\/<idAirport>\/parkings",
        "route": "api\/airports\/:idAirport\/parkings",
        "params": [
            "idAirport"
        ]
    },
    "api\/airports\/terminal-parkings": {
        "original_route": "GET api\/airports\/<idAirport>\/terminals\/<idTerminal>\/parkings",
        "route": "api\/airports\/:idAirport\/terminals\/:idTerminal\/parkings",
        "params": [
            "idAirport",
            "idTerminal"
        ]
    },
    "api\/parkings\/index": {
        "original_route": "GET api\/parkings",
        "route": "api\/parkings",
        "params": []
    },
    "api\/parkings\/view": {
        "original_route": "GET api\/parkings\/<idParking:\\d+>",
        "route": "api\/parkings\/:idParking",
        "params": [
            "idParking"
        ]
    },
    "api\/parkings\/nearby-parkings": {
        "original_route": "GET api\/parkings\/<idParking:\\d+>\/nearby-parkings",
        "route": "api\/parkings\/:idParking\/nearby-parkings",
        "params": [
            "idParking"
        ]
    },
    "api\/parkings\/season-tickets": {
        "original_route": "GET api\/parkings\/<idParking:\\d+>\/season-tickets",
        "route": "api\/parkings\/:idParking\/season-tickets",
        "params": [
            "idParking"
        ]
    },
    "api\/parkings\/booking-availability": {
        "original_route": "GET api\/parkings\/<idParking:\\d+>\/bookings\/availability",
        "route": "api\/parkings\/:idParking\/bookings\/availability",
        "params": [
            "idParking"
        ]
    },
    "api\/parkings\/booking-products-availability": {
        "original_route": "GET api\/parkings\/<idParking:\\d+>\/bookings\/availability\/products",
        "route": "api\/parkings\/:idParking\/bookings\/availability\/products",
        "params": [
            "idParking"
        ]
    },
    "api\/parkings\/index-by-opening-mode-viat": {
        "original_route": "GET api\/parkings\/viat",
        "route": "api\/parkings\/viat",
        "params": []
    },
    "api\/parkings\/index-by-opening-mode-viat-and-country": {
        "original_route": "GET api\/parkings\/viat\/country\/<idCountry:\\d+>",
        "route": "api\/parkings\/viat\/country\/:idCountry",
        "params": [
            "idCountry"
        ]
    },
    "api\/parkings\/pois": {
        "original_route": "GET api\/parkings\/<idParking:\\d+>\/pois",
        "route": "api\/parkings\/:idParking\/pois",
        "params": [
            "idParking"
        ]
    },
    "api\/vehicles\/index": {
        "original_route": "GET api\/vehicles",
        "route": "api\/vehicles",
        "params": []
    },
    "api\/vehicles\/paginated": {
        "original_route": "GET api\/vehicles\/paginated",
        "route": "api\/vehicles\/paginated",
        "params": []
    },
    "api\/vehicles\/brands": {
        "original_route": "GET api\/vehicles\/brands",
        "route": "api\/vehicles\/brands",
        "params": []
    },
    "api\/vehicles\/colors": {
        "original_route": "GET api\/vehicles\/colors",
        "route": "api\/vehicles\/colors",
        "params": []
    },
    "api\/vehicles\/search-by-number-plate": {
        "original_route": "GET api\/vehicles\/search-by-number-plate\/<numberPlate>",
        "route": "api\/vehicles\/search-by-number-plate\/:numberPlate",
        "params": [
            "numberPlate"
        ]
    },
    "api\/vehicles\/create": {
        "original_route": "POST api\/vehicles",
        "route": "api\/vehicles",
        "params": []
    },
    "api\/vehicles\/update-km": {
        "original_route": "PUT api\/vehicles\/<idVehicle:\\d+>\/update-km",
        "route": "api\/vehicles\/:idVehicle\/update-km",
        "params": [
            "idVehicle"
        ]
    },
    "api\/vehicles\/update": {
        "original_route": "PUT api\/vehicles\/<idVehicle>",
        "route": "api\/vehicles\/:idVehicle",
        "params": [
            "idVehicle"
        ]
    },
    "api\/vehicles\/delete": {
        "original_route": "DELETE api\/vehicles\/<idVehicle>",
        "route": "api\/vehicles\/:idVehicle",
        "params": [
            "idVehicle"
        ]
    },
    "api\/vehicles\/get-environmental-label": {
        "original_route": "GET api\/vehicles\/<numberPlate>\/environmental-label",
        "route": "api\/vehicles\/:numberPlate\/environmental-label",
        "params": [
            "numberPlate"
        ]
    },
    "api\/vehicles\/get-auto-open-permission": {
        "original_route": "GET api\/vehicles\/<numberPlate>\/auto-open-permission",
        "route": "api\/vehicles\/:numberPlate\/auto-open-permission",
        "params": [
            "numberPlate"
        ]
    },
    "api\/vehicles\/enable-auto-open-permission": {
        "original_route": "PUT api\/vehicles\/<idVehicle>\/auto-open-permission",
        "route": "api\/vehicles\/:idVehicle\/auto-open-permission",
        "params": [
            "idVehicle"
        ]
    },
    "api\/vehicles\/disable-auto-open-permission": {
        "original_route": "DELETE api\/vehicles\/<idVehicle>\/auto-open-permission",
        "route": "api\/vehicles\/:idVehicle\/auto-open-permission",
        "params": [
            "idVehicle"
        ]
    },
    "api\/vehicles\/upload-number-plates-csv": {
        "original_route": "POST api\/vehicles\/upload-number-plates-csv",
        "route": "api\/vehicles\/upload-number-plates-csv",
        "params": []
    },
    "api\/vehicles\/download-number-plates-csv": {
        "original_route": "GET api\/vehicles\/download-number-plates-csv",
        "route": "api\/vehicles\/download-number-plates-csv",
        "params": []
    },
    "api\/user\/update": {
        "original_route": "PUT api\/user\/update",
        "route": "api\/user\/update",
        "params": []
    },
    "api\/user-preferences\/update": {
        "original_route": "PUT api\/user-preferences\/save",
        "route": "api\/user-preferences\/save",
        "params": []
    },
    "api\/user\/on-boarding": {
        "original_route": "POST api\/users\/complete-profile",
        "route": "api\/users\/complete-profile",
        "params": []
    },
    "api\/user\/check-user-exists": {
        "original_route": "GET api\/users\/exists\/<email>",
        "route": "api\/users\/exists\/:email",
        "params": [
            "email"
        ]
    },
    "api\/user\/check-advanced-vehicles-admin": {
        "original_route": "GET api\/users\/check-advanced-vehicles-admin",
        "route": "api\/users\/check-advanced-vehicles-admin",
        "params": []
    },
    "api\/contracts\/index-by-user": {
        "original_route": "GET api\/users\/contracts",
        "route": "api\/users\/contracts",
        "params": []
    },
    "api\/contracts\/old-by-user": {
        "original_route": "GET api\/users\/contracts\/old",
        "route": "api\/users\/contracts\/old",
        "params": []
    },
    "api\/deposit-tier\/get-deposit-tiers": {
        "original_route": "GET api\/countries\/<countryId:\\d+>\/deposit-tiers",
        "route": "api\/countries\/:countryId\/deposit-tiers",
        "params": [
            "countryId"
        ]
    },
    "api\/deposit-tier\/get-auto-deposit-tiers": {
        "original_route": "GET api\/countries\/<countryId:\\d+>\/auto-deposit-tiers",
        "route": "api\/countries\/:countryId\/auto-deposit-tiers",
        "params": [
            "countryId"
        ]
    },
    "api\/deposit-tier\/get-booking-deposit-tiers": {
        "original_route": "GET api\/countries\/<countryId:\\d+>\/deposit-tiers\/booking",
        "route": "api\/countries\/:countryId\/deposit-tiers\/booking",
        "params": [
            "countryId"
        ]
    },
    "api\/language\/index-by-country-for-notifications": {
        "original_route": "GET api\/countries\/<countryId:\\d+>\/languages",
        "route": "api\/countries\/:countryId\/languages",
        "params": [
            "countryId"
        ]
    },
    "api\/booking\/get-overlapped-bookings": {
        "original_route": "GET api\/booking\/overlapped",
        "route": "api\/booking\/overlapped",
        "params": []
    },
    "api\/booking\/view": {
        "original_route": "GET api\/booking\/<idBooking>",
        "route": "api\/booking\/:idBooking",
        "params": [
            "idBooking"
        ]
    },
    "api\/booking\/cancel": {
        "original_route": "DELETE api\/booking\/<idBooking>",
        "route": "api\/booking\/:idBooking",
        "params": [
            "idBooking"
        ]
    },
    "api\/booking\/create-new-user-booking": {
        "original_route": "POST api\/booking\/create-new-user-booking",
        "route": "api\/booking\/create-new-user-booking",
        "params": []
    },
    "api\/booking\/create-logged-user-booking": {
        "original_route": "POST api\/booking\/create-logged-user-booking",
        "route": "api\/booking\/create-logged-user-booking",
        "params": []
    },
    "api\/booking\/update-vehicle": {
        "original_route": "PATCH api\/booking\/<idBooking>\/update-vehicle",
        "route": "api\/booking\/:idBooking\/update-vehicle",
        "params": [
            "idBooking"
        ]
    },
    "api\/booking\/availability-update-dates": {
        "original_route": "GET api\/booking\/<idBooking>\/availability\/update-dates",
        "route": "api\/booking\/:idBooking\/availability\/update-dates",
        "params": [
            "idBooking"
        ]
    },
    "api\/booking\/update-dates": {
        "original_route": "PATCH api\/booking\/<idBooking>\/update-dates",
        "route": "api\/booking\/:idBooking\/update-dates",
        "params": [
            "idBooking"
        ]
    },
    "api\/user\/show": {
        "original_route": "GET api\/user\/show",
        "route": "api\/user\/show",
        "params": []
    },
    "api\/user\/log-out": {
        "original_route": "DELETE api\/user\/log-out",
        "route": "api\/user\/log-out",
        "params": []
    },
    "api\/coupon-order-product\/list": {
        "original_route": "GET api\/merchants\/shop\/coupon-order-products",
        "route": "api\/merchants\/shop\/coupon-order-products",
        "params": []
    },
    "api\/coupon-order\/create": {
        "original_route": "POST api\/merchants\/shop\/coupon-order",
        "route": "api\/merchants\/shop\/coupon-order",
        "params": []
    },
    "api\/coupon-order\/list": {
        "original_route": "GET api\/merchants\/shop\/coupon-orders",
        "route": "api\/merchants\/shop\/coupon-orders",
        "params": []
    },
    "api\/coupon-order\/show": {
        "original_route": "GET api\/merchants\/shop\/coupon-order\/<id:\\d+>",
        "route": "api\/merchants\/shop\/coupon-order\/:id",
        "params": [
            "id"
        ]
    },
    "api\/coupon-order\/config": {
        "original_route": "GET api\/merchants\/shop\/coupon-order-config",
        "route": "api\/merchants\/shop\/coupon-order-config",
        "params": []
    },
    "api\/business\/show": {
        "original_route": "GET api\/merchants\/business\/show",
        "route": "api\/merchants\/business\/show",
        "params": []
    },
    "api\/business\/update": {
        "original_route": "PUT api\/merchants\/business\/update",
        "route": "api\/merchants\/business\/update",
        "params": []
    },
    "api\/credit-card\/list": {
        "original_route": "GET api\/merchants\/shop\/credit-cards",
        "route": "api\/merchants\/shop\/credit-cards",
        "params": []
    },
    "api\/credit-card\/update": {
        "original_route": "PUT api\/merchants\/shop\/credit-card\/<id:\\d+>",
        "route": "api\/merchants\/shop\/credit-card\/:id",
        "params": [
            "id"
        ]
    },
    "api\/wallet\/show": {
        "original_route": "GET api\/merchants\/shop\/wallet",
        "route": "api\/merchants\/shop\/wallet",
        "params": []
    },
    "api\/coupon-count\/list": {
        "original_route": "GET api\/merchants\/shop\/coupon-count-list",
        "route": "api\/merchants\/shop\/coupon-count-list",
        "params": []
    },
    "api\/credit-card\/create-setup-intent": {
        "original_route": "POST api\/credit-cards\/gateway\/<gatewayId:\\d+>\/setup-intent",
        "route": "api\/credit-cards\/gateway\/:gatewayId\/setup-intent",
        "params": [
            "gatewayId"
        ]
    },
    "api\/credit-card\/info": {
        "original_route": "GET api\/credit-cards\/gateway\/<gatewayId:\\d+>\/info\/<setUpIntentId:\\w+>",
        "route": "api\/credit-cards\/gateway\/:gatewayId\/info\/:setUpIntentId",
        "params": [
            "gatewayId",
            "setUpIntentId"
        ]
    },
    "api\/credit-card\/create": {
        "original_route": "POST api\/credit-card\/create",
        "route": "api\/credit-card\/create",
        "params": []
    },
    "api\/credit-card\/delete": {
        "original_route": "DELETE api\/credit-cards\/<id:\\d+>",
        "route": "api\/credit-cards\/:id",
        "params": [
            "id"
        ]
    },
    "api\/wallet\/user-wallets": {
        "original_route": "GET api\/wallets",
        "route": "api\/wallets",
        "params": []
    },
    "api\/wallet\/fetch": {
        "original_route": "GET api\/wallets\/<idWallet:\\d+>",
        "route": "api\/wallets\/:idWallet",
        "params": [
            "idWallet"
        ]
    },
    "api\/wallet\/update-auto-deposit": {
        "original_route": "PUT api\/wallets\/<idWallet:\\d+>\/auto-recarga",
        "route": "api\/wallets\/:idWallet\/auto-recarga",
        "params": [
            "idWallet"
        ]
    },
    "api\/wallet\/auto-deposit": {
        "original_route": "GET api\/wallets\/<idWallet:\\d+>\/auto-recarga",
        "route": "api\/wallets\/:idWallet\/auto-recarga",
        "params": [
            "idWallet"
        ]
    },
    "api\/wallet\/wallet-users": {
        "original_route": "GET api\/wallets\/<idWallet:\\d+>\/beneficiarios",
        "route": "api\/wallets\/:idWallet\/beneficiarios",
        "params": [
            "idWallet"
        ]
    },
    "api\/wallet\/change-name": {
        "original_route": "PUT api\/wallets\/<idWallet:\\d+>\/cambiar-nombre",
        "route": "api\/wallets\/:idWallet\/cambiar-nombre",
        "params": [
            "idWallet"
        ]
    },
    "api\/wallet\/create": {
        "original_route": "POST api\/wallet\/create",
        "route": "api\/wallet\/create",
        "params": []
    },
    "api\/wallet-invoice-data\/view": {
        "original_route": "GET api\/wallets\/<idWallet:\\d+>\/invoice-data",
        "route": "api\/wallets\/:idWallet\/invoice-data",
        "params": [
            "idWallet"
        ]
    },
    "api\/wallet-invoice-data\/update": {
        "original_route": "PUT api\/wallets\/<idWallet:\\d+>\/invoice-data",
        "route": "api\/wallets\/:idWallet\/invoice-data",
        "params": [
            "idWallet"
        ]
    },
    "api\/wallet\/confirm-payment-intent": {
        "original_route": "api\/wallets\/<idWallet:\\d+>\/confirm-payment-intent",
        "route": "api\/wallets\/:idWallet\/confirm-payment-intent",
        "params": [
            "idWallet"
        ]
    },
    "api\/wallet\/list": {
        "original_route": "GET api\/wallets\/list",
        "route": "api\/wallets\/list",
        "params": []
    },
    "api\/wallet\/create-deposit": {
        "original_route": "POST api\/wallets\/create-deposit",
        "route": "api\/wallets\/create-deposit",
        "params": []
    },
    "api\/wallet\/associate-credit-card": {
        "original_route": "POST api\/wallet\/<idWallet:\\d+>\/associate-credit-card\/<idCreditCard:\\d+>",
        "route": "api\/wallet\/:idWallet\/associate-credit-card\/:idCreditCard",
        "params": [
            "idWallet",
            "idCreditCard"
        ]
    },
    "api\/wallet\/movements": {
        "original_route": "GET api\/wallets\/<walletId:\\d+>\/movements",
        "route": "api\/wallets\/:walletId\/movements",
        "params": [
            "walletId"
        ]
    },
    "api\/wallet\/request-movements-csv": {
        "original_route": "GET api\/wallets\/<walletId:\\d+>\/request-movements-csv",
        "route": "api\/wallets\/:walletId\/request-movements-csv",
        "params": [
            "walletId"
        ]
    },
    "api\/wallet-corporate\/beneficiaries-list": {
        "original_route": "GET api\/wallet-corporate\/<walletId:\\d+>\/beneficiaries",
        "route": "api\/wallet-corporate\/:walletId\/beneficiaries",
        "params": [
            "walletId"
        ]
    },
    "api\/wallet-corporate\/get-all-beneficiaries": {
        "original_route": "GET api\/wallet-corporate\/<walletId:\\d+>\/beneficiaries-all",
        "route": "api\/wallet-corporate\/:walletId\/beneficiaries-all",
        "params": [
            "walletId"
        ]
    },
    "api\/wallet-corporate\/get-beneficiary": {
        "original_route": "GET api\/wallet-corporate\/<walletId:\\d+>\/beneficiary",
        "route": "api\/wallet-corporate\/:walletId\/beneficiary",
        "params": [
            "walletId"
        ]
    },
    "api\/wallet-corporate\/price-per-beneficiary": {
        "original_route": "GET api\/wallet-corporate\/<walletId:\\d+>\/price-per-beneficiary",
        "route": "api\/wallet-corporate\/:walletId\/price-per-beneficiary",
        "params": [
            "walletId"
        ]
    },
    "api\/wallet-corporate\/add-beneficiary": {
        "original_route": "POST api\/wallet-corporate\/<walletId:\\d+>\/add-beneficiary",
        "route": "api\/wallet-corporate\/:walletId\/add-beneficiary",
        "params": [
            "walletId"
        ]
    },
    "api\/wallet-corporate\/add-beneficiaries-from-csv": {
        "original_route": "POST api\/wallet-corporate\/<walletId:\\d+>\/add-beneficiaries-from-csv",
        "route": "api\/wallet-corporate\/:walletId\/add-beneficiaries-from-csv",
        "params": [
            "walletId"
        ]
    },
    "api\/wallet-corporate\/remove-beneficiary": {
        "original_route": "DELETE api\/wallet-corporate\/<walletId:\\d+>\/remove-beneficiary\/<idBeneficiary:\\d+>",
        "route": "api\/wallet-corporate\/:walletId\/remove-beneficiary\/:idBeneficiary",
        "params": [
            "walletId",
            "idBeneficiary"
        ]
    },
    "api\/wallet\/update-plates-whitelist-enabled": {
        "original_route": "PATCH api\/wallet-corporate\/<idWallet:\\d+>\/plates-whitelist-enabled",
        "route": "api\/wallet-corporate\/:idWallet\/plates-whitelist-enabled",
        "params": [
            "idWallet"
        ]
    },
    "api\/wallet-corporate-plates-whitelist\/get-all-plates": {
        "original_route": "GET api\/wallet-corporate\/<walletId:\\d+>\/plates-whitelist",
        "route": "api\/wallet-corporate\/:walletId\/plates-whitelist",
        "params": [
            "walletId"
        ]
    },
    "api\/wallet-corporate-plates-whitelist\/search-by-plate": {
        "original_route": "GET api\/wallet-corporate\/<walletId:\\d+>\/plates-whitelist\/by-plate",
        "route": "api\/wallet-corporate\/:walletId\/plates-whitelist\/by-plate",
        "params": [
            "walletId"
        ]
    },
    "api\/wallet-corporate-plates-whitelist\/add-number-plate": {
        "original_route": "POST api\/wallet-corporate\/<walletId:\\d+>\/plates-whitelist\/plate",
        "route": "api\/wallet-corporate\/:walletId\/plates-whitelist\/plate",
        "params": [
            "walletId"
        ]
    },
    "api\/wallet-corporate-plates-whitelist\/delete-number-plate": {
        "original_route": "DELETE api\/wallet-corporate\/<walletId:\\d+>\/plates-whitelist\/plate\/<walletPlateId:\\d+>",
        "route": "api\/wallet-corporate\/:walletId\/plates-whitelist\/plate\/:walletPlateId",
        "params": [
            "walletId",
            "walletPlateId"
        ]
    },
    "api\/wallet-corporate-plates-whitelist\/delete-all": {
        "original_route": "DELETE api\/wallet-corporate\/<walletId:\\d+>\/plates-whitelist",
        "route": "api\/wallet-corporate\/:walletId\/plates-whitelist",
        "params": [
            "walletId"
        ]
    },
    "api\/wallet-corporate-plates-whitelist\/upload-number-plates-csv": {
        "original_route": "POST api\/wallet-corporate\/<walletId:\\d+>\/plates-whitelist\/upload-number-plates-csv",
        "route": "api\/wallet-corporate\/:walletId\/plates-whitelist\/upload-number-plates-csv",
        "params": [
            "walletId"
        ]
    },
    "api\/wallet-preferences\/index": {
        "original_route": "GET api\/wallet-preferences\/<walletId:\\d+>",
        "route": "api\/wallet-preferences\/:walletId",
        "params": [
            "walletId"
        ]
    },
    "api\/wallet-preferences\/update": {
        "original_route": "PUT api\/wallet-preferences\/<walletId:\\d+>",
        "route": "api\/wallet-preferences\/:walletId",
        "params": [
            "walletId"
        ]
    },
    "api\/season-ticket-contract\/cancel": {
        "original_route": "DELETE api\/season-ticket-contract\/<idContract:\\d+>",
        "route": "api\/season-ticket-contract\/:idContract",
        "params": [
            "idContract"
        ]
    },
    "api\/parking-extra-service\/get": {
        "original_route": "GET api\/parking\/<idParking>\/services",
        "route": "api\/parking\/:idParking\/services",
        "params": [
            "idParking"
        ]
    },
    "api\/country\/geolocation-by-ip": {
        "original_route": "GET api\/country",
        "route": "api\/country",
        "params": []
    },
    "api\/country\/accepted-countries": {
        "original_route": "GET api\/accepted-countries",
        "route": "api\/accepted-countries",
        "params": []
    },
    "api\/country\/loggable-countries": {
        "original_route": "GET api\/loggable-countries",
        "route": "api\/loggable-countries",
        "params": []
    },
    "api\/gdpr-conditions\/get-terms-to-approve": {
        "original_route": "GET api\/gdpr-conditions\/to-approve",
        "route": "api\/gdpr-conditions\/to-approve",
        "params": []
    },
    "api\/gdpr-conditions\/approve": {
        "original_route": "POST api\/gdpr-conditions\/to-approve",
        "route": "api\/gdpr-conditions\/to-approve",
        "params": []
    },
    "api\/privacy\/get": {
        "original_route": "GET api\/privacy\/get",
        "route": "api\/privacy\/get",
        "params": []
    },
    "api\/privacy\/create-update": {
        "original_route": "PUT api\/privacy\/update",
        "route": "api\/privacy\/update",
        "params": []
    },
    "api\/account\/login": {
        "original_route": "POST api\/account\/login",
        "route": "api\/account\/login",
        "params": []
    },
    "api\/account\/social-login": {
        "original_route": "POST api\/account\/social-login",
        "route": "api\/account\/social-login",
        "params": []
    },
    "api\/account\/create": {
        "original_route": "POST api\/account\/create",
        "route": "api\/account\/create",
        "params": []
    },
    "api\/petrol-stations\/index": {
        "original_route": "GET api\/petrol-stations",
        "route": "api\/petrol-stations",
        "params": []
    },
    "api\/prebooking\/fetch": {
        "original_route": "GET api\/prebookings\/<idPrebooking:\\d+>",
        "route": "api\/prebookings\/:idPrebooking",
        "params": [
            "idPrebooking"
        ]
    },
    "api\/prebooking\/update": {
        "original_route": "PUT api\/prebookings\/<idPrebooking:\\d+>",
        "route": "api\/prebookings\/:idPrebooking",
        "params": [
            "idPrebooking"
        ]
    },
    "api\/prebooking\/create": {
        "original_route": "POST api\/prebookings\/create",
        "route": "api\/prebookings\/create",
        "params": []
    },
    "api\/booked-parking-pass\/cancel": {
        "original_route": "PUT api\/booked-parking-pass\/<passId:\\d+>\/cancel",
        "route": "api\/booked-parking-pass\/:passId\/cancel",
        "params": [
            "passId"
        ]
    },
    "api\/booked-parking-pass\/create": {
        "original_route": "POST api\/booked-parking-pass",
        "route": "api\/booked-parking-pass",
        "params": []
    },
    "api\/parking-fine-usa\/notify-claim": {
        "original_route": "POST api\/parking-fine-usa\/town\/<townId>\/claim",
        "route": "api\/parking-fine-usa\/town\/:townId\/claim",
        "params": [
            "townId"
        ]
    },
    "api\/parking-fine-usa\/check-fine-with-extra-info": {
        "original_route": "GET api\/parking-fine-usa\/town\/<townId>\/fine\/<bulletinNumber>\/brand\/<brandId>",
        "route": "api\/parking-fine-usa\/town\/:townId\/fine\/:bulletinNumber\/brand\/:brandId",
        "params": [
            "townId",
            "bulletinNumber",
            "brandId"
        ]
    },
    "api\/parking-fine-usa\/cancel-parking-fine": {
        "original_route": "POST api\/parking-fine-usa\/cancel-parking-fine",
        "route": "api\/parking-fine-usa\/cancel-parking-fine",
        "params": []
    },
    "api\/contact\/send-form": {
        "original_route": "POST api\/contact\/send-form",
        "route": "api\/contact\/send-form",
        "params": []
    },
    "api\/booking-promotional-codes\/validate": {
        "original_route": "GET api\/booking-promotional-codes\/<code>\/parking\/<parkingId:\\d+>\/validate",
        "route": "api\/booking-promotional-codes\/:code\/parking\/:parkingId\/validate",
        "params": [
            "code",
            "parkingId"
        ]
    },
    "api\/point-of-interest\/list": {
        "original_route": "GET api\/poi\/list",
        "route": "api\/poi\/list",
        "params": []
    },
    "api\/point-of-interest\/list-with-events": {
        "original_route": "GET api\/poi\/list-with-events",
        "route": "api\/poi\/list-with-events",
        "params": []
    },
    "api\/invoices\/xlsx": {
        "original_route": "GET api\/invoice\/xlsx\/<invoiceUid>",
        "route": "api\/invoice\/xlsx\/:invoiceUid",
        "params": [
            "invoiceUid"
        ]
    },
    "api\/invoices\/list": {
        "original_route": "GET api\/invoices",
        "route": "api\/invoices",
        "params": []
    },
    "api\/provinces\/list": {
        "original_route": "GET api\/provinces",
        "route": "api\/provinces",
        "params": []
    },
    "api\/provinces\/list-for-shop": {
        "original_route": "GET api\/provinces-for-shop",
        "route": "api\/provinces-for-shop",
        "params": []
    },
    "api\/provinces\/towns-by-province": {
        "original_route": "GET api\/province\/<id:\\d+>\/towns",
        "route": "api\/province\/:id\/towns",
        "params": [
            "id"
        ]
    },
    "api\/charging-points\/index": {
        "original_route": "GET api\/charging-points",
        "route": "api\/charging-points",
        "params": []
    },
    "api\/charging-points\/availability": {
        "original_route": "GET api\/charging-points\/<chargingPointId>\/availability",
        "route": "api\/charging-points\/:chargingPointId\/availability",
        "params": [
            "chargingPointId"
        ]
    },
    "api\/charging-points\/view": {
        "original_route": "GET api\/charging-points\/<chargingPointId>",
        "route": "api\/charging-points\/:chargingPointId",
        "params": [
            "chargingPointId"
        ]
    },
    "api\/charging-point-bookings\/view": {
        "original_route": "GET api\/charging-point-bookings\/<chargingPointBookingId:\\d+>",
        "route": "api\/charging-point-bookings\/:chargingPointBookingId",
        "params": [
            "chargingPointBookingId"
        ]
    },
    "api\/charging-point-bookings\/cancel": {
        "original_route": "DELETE api\/charging-point-bookings\/<chargingPointBookingId:\\d+>",
        "route": "api\/charging-point-bookings\/:chargingPointBookingId",
        "params": [
            "chargingPointBookingId"
        ]
    },
    "api\/vehicle-inspection-stations\/index": {
        "original_route": "GET api\/vehicle-inspection-stations",
        "route": "api\/vehicle-inspection-stations",
        "params": []
    },
    "api\/vehicle-inspection-stations\/view": {
        "original_route": "GET api\/vehicle-inspection-stations\/<stationId:\\d+>",
        "route": "api\/vehicle-inspection-stations\/:stationId",
        "params": [
            "stationId"
        ]
    },
    "api\/vehicle-inspection-stations\/occupation": {
        "original_route": "GET api\/vehicle-inspection-stations\/<stationId:\\d+>\/<stationProductId:\\d+>\/occupation",
        "route": "api\/vehicle-inspection-stations\/:stationId\/:stationProductId\/occupation",
        "params": [
            "stationId",
            "stationProductId"
        ]
    },
    "api\/vehicle-inspection-stations\/occupation-for-days": {
        "original_route": "GET api\/vehicle-inspection-stations\/<stationId:\\d+>\/<stationProductId:\\d+>\/occupation\/days",
        "route": "api\/vehicle-inspection-stations\/:stationId\/:stationProductId\/occupation\/days",
        "params": [
            "stationId",
            "stationProductId"
        ]
    },
    "api\/vehicle-inspection-stations\/occupation-intra-day": {
        "original_route": "GET api\/vehicle-inspection-stations\/<stationId:\\d+>\/<stationProductId:\\d+>\/occupation\/intra-day",
        "route": "api\/vehicle-inspection-stations\/:stationId\/:stationProductId\/occupation\/intra-day",
        "params": [
            "stationId",
            "stationProductId"
        ]
    },
    "api\/vehicle-inspection-station-products\/get-filtered-by-station": {
        "original_route": "GET api\/vehicle-inspection-station-products\/station\/<stationId:\\d+>",
        "route": "api\/vehicle-inspection-station-products\/station\/:stationId",
        "params": [
            "stationId"
        ]
    },
    "api\/vehicle-inspection-booking\/cancel": {
        "original_route": "DELETE api\/vehicle-inspection-booking\/<bookingId>",
        "route": "api\/vehicle-inspection-booking\/:bookingId",
        "params": [
            "bookingId"
        ]
    },
    "api\/vehicle-inspection-booking\/create-logged-user-booking": {
        "original_route": "POST api\/vehicle-inspection-booking\/create-logged-user-booking",
        "route": "api\/vehicle-inspection-booking\/create-logged-user-booking",
        "params": []
    },
    "api\/vehicle-inspection-booking\/create-new-user-booking": {
        "original_route": "POST api\/vehicle-inspection-booking\/create-new-user-booking",
        "route": "api\/vehicle-inspection-booking\/create-new-user-booking",
        "params": []
    },
    "api\/vehicle-inspection-booking\/view": {
        "original_route": "GET api\/vehicle-inspection-booking\/<bookingId>",
        "route": "api\/vehicle-inspection-booking\/:bookingId",
        "params": [
            "bookingId"
        ]
    },
    "api\/rotation\/view": {
        "original_route": "GET api\/rotation\/<idRotation>",
        "route": "api\/rotation\/:idRotation",
        "params": [
            "idRotation"
        ]
    },
    "api\/viat\/highway-discounts": {
        "original_route": "GET api\/viat\/highway-discounts",
        "route": "api\/viat\/highway-discounts",
        "params": []
    },
    "api\/feature-flags\/all": {
        "original_route": "GET api\/feature-flags",
        "route": "api\/feature-flags",
        "params": []
    },
    "api\/config\/get": {
        "original_route": "GET api\/config",
        "route": "api\/config",
        "params": []
    },
    "api\/viat-products\/get-price": {
        "original_route": "GET api\/viat-products\/price",
        "route": "api\/viat-products\/price",
        "params": []
    },
    "api\/pagatelia-orders\/get-by-hash": {
        "original_route": "GET api\/pagatelia-orders\/hash\/<hash>",
        "route": "api\/pagatelia-orders\/hash\/:hash",
        "params": [
            "hash"
        ]
    },
    "api\/viat-order\/get": {
        "original_route": "GET api\/viat-order\/<id:\\d+>",
        "route": "api\/viat-order\/:id",
        "params": [
            "id"
        ]
    },
    "api\/viat-order\/cancel": {
        "original_route": "DELETE api\/viat-order\/cancel",
        "route": "api\/viat-order\/cancel",
        "params": []
    },
    "api\/viat-order\/list": {
        "original_route": "GET api\/viat-orders",
        "route": "api\/viat-orders",
        "params": []
    },
    "api\/viat-order\/pay-logged-user-order": {
        "original_route": "POST api\/viat-order\/pay-logged-user-order",
        "route": "api\/viat-order\/pay-logged-user-order",
        "params": []
    },
    "api\/viat-order\/pay-new-user-order": {
        "original_route": "POST api\/viat-order\/pay-new-user-order",
        "route": "api\/viat-order\/pay-new-user-order",
        "params": []
    },
    "api\/viat-order\/create": {
        "original_route": "POST api\/viat-product\/<productId>\/viat-order",
        "route": "api\/viat-product\/:productId\/viat-order",
        "params": [
            "productId"
        ]
    },
    "api\/viat-order\/update": {
        "original_route": "api\/viat-order",
        "route": "api\/viat-order",
        "params": []
    },
    "api\/mobe\/list": {
        "original_route": "GET api\/mobes",
        "route": "api\/mobes",
        "params": []
    },
    "api\/mobe\/list-enabled": {
        "original_route": "GET api\/mobes\/enabled",
        "route": "api\/mobes\/enabled",
        "params": []
    },
    "api\/mobe\/bind-vehicle": {
        "original_route": "api\/mobes\/<idMobe:\\d+>\/bind-vehicle",
        "route": "api\/mobes\/:idMobe\/bind-vehicle",
        "params": [
            "idMobe"
        ]
    },
    "api\/mobe\/unbind-vehicle": {
        "original_route": "api\/mobes\/<idMobe:\\d+>\/unbind-vehicle",
        "route": "api\/mobes\/:idMobe\/unbind-vehicle",
        "params": [
            "idMobe"
        ]
    },
    "api\/mobe\/bind-wallet": {
        "original_route": "api\/mobes\/<idMobe:\\d+>\/bind-wallet",
        "route": "api\/mobes\/:idMobe\/bind-wallet",
        "params": [
            "idMobe"
        ]
    },
    "api\/mobe\/unassign": {
        "original_route": "api\/mobes\/<idMobe:\\d+>\/unassign",
        "route": "api\/mobes\/:idMobe\/unassign",
        "params": [
            "idMobe"
        ]
    },
    "api\/toll\/list": {
        "original_route": "GET api\/tolls",
        "route": "api\/tolls",
        "params": []
    },
    "api\/workshop-booking\/get": {
        "original_route": "GET api\/workshop-bookings\/<bookingId>",
        "route": "api\/workshop-bookings\/:bookingId",
        "params": [
            "bookingId"
        ]
    },
    "api\/workshop-booking\/assign-products": {
        "original_route": "PUT api\/workshop-bookings\/<bookingId>\/products",
        "route": "api\/workshop-bookings\/:bookingId\/products",
        "params": [
            "bookingId"
        ]
    },
    "api\/workshop-booking\/assign-workshop": {
        "original_route": "PUT api\/workshop-bookings\/<bookingId>\/workshop",
        "route": "api\/workshop-bookings\/:bookingId\/workshop",
        "params": [
            "bookingId"
        ]
    },
    "api\/workshop-booking\/set-appointment-date": {
        "original_route": "PUT api\/workshop-bookings\/<bookingId>\/appointment\/<appointmentDate>",
        "route": "api\/workshop-bookings\/:bookingId\/appointment\/:appointmentDate",
        "params": [
            "bookingId",
            "appointmentDate"
        ]
    },
    "api\/workshop-booking\/confirm-appointment": {
        "original_route": "POST api\/workshop-bookings\/<bookingId>\/confirmation",
        "route": "api\/workshop-bookings\/:bookingId\/confirmation",
        "params": [
            "bookingId"
        ]
    },
    "api\/workshop-booking\/pay": {
        "original_route": "POST api\/workshop-bookings\/<bookingId>\/payment",
        "route": "api\/workshop-bookings\/:bookingId\/payment",
        "params": [
            "bookingId"
        ]
    },
    "api\/workshop-booking\/create": {
        "original_route": "POST api\/workshop-bookings",
        "route": "api\/workshop-bookings",
        "params": []
    },
    "api\/workshop-booking\/cancel": {
        "original_route": "DELETE api\/workshop-bookings\/<bookingId>",
        "route": "api\/workshop-bookings\/:bookingId",
        "params": [
            "bookingId"
        ]
    },
    "api\/workshops\/index": {
        "original_route": "GET api\/workshop-bookings\/<bookingId>\/suitable-workshops",
        "route": "api\/workshop-bookings\/:bookingId\/suitable-workshops",
        "params": [
            "bookingId"
        ]
    },
    "api\/workshops\/availability": {
        "original_route": "GET api\/workshop-bookings\/<bookingId>\/workshop\/availability",
        "route": "api\/workshop-bookings\/:bookingId\/workshop\/availability",
        "params": [
            "bookingId"
        ]
    },
    "api\/workshop-products\/index": {
        "original_route": "GET api\/workshop-products",
        "route": "api\/workshop-products",
        "params": []
    },
    "api\/two-factor-authentication\/activate-email-method": {
        "original_route": "POST api\/2fa\/email-method",
        "route": "api\/2fa\/email-method",
        "params": []
    },
    "api\/two-factor-authentication\/deactivate-email-method": {
        "original_route": "DELETE api\/2fa\/email-method",
        "route": "api\/2fa\/email-method",
        "params": []
    },
    "api\/two-factor-authentication\/get-active-method": {
        "original_route": "GET api\/2fa\/active-method",
        "route": "api\/2fa\/active-method",
        "params": []
    },
    "api\/town\/all-having-fines": {
        "original_route": "GET api\/towns\/country\/<countryId>\/having-fines",
        "route": "api\/towns\/country\/:countryId\/having-fines",
        "params": [
            "countryId"
        ]
    },
    "api\/trailers\/paginated": {
        "original_route": "GET api\/trailers\/paginated",
        "route": "api\/trailers\/paginated",
        "params": []
    },
    "api\/trailers\/create": {
        "original_route": "POST api\/trailers",
        "route": "api\/trailers",
        "params": []
    },
    "api\/trailers\/delete": {
        "original_route": "DELETE api\/trailers\/<id:\\d+>",
        "route": "api\/trailers\/:id",
        "params": [
            "id"
        ]
    },
    "api\/trailers\/upload-number-plates-csv": {
        "original_route": "POST api\/trailers\/upload-number-plates-csv",
        "route": "api\/trailers\/upload-number-plates-csv",
        "params": []
    }
};