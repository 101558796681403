// be careful!
// generated file with Yii routes with webpack. Don't update lines here!
export const json = {
    "ecu\/site\/index": {
        "original_route": "ecu\/en-us",
        "route": "mobile-parking-payments\/ecu\/en-us",
        "params": []
    },
    "site\/error": {
        "original_route": "ecu\/en-us\/error",
        "route": "mobile-parking-payments\/ecu\/en-us\/error",
        "params": []
    },
    "site\/terms": {
        "original_route": "ecu\/en-us\/legal-terms",
        "route": "mobile-parking-payments\/ecu\/en-us\/legal-terms",
        "params": []
    },
    "ecu\/sitemap\/lang": {
        "original_route": "ecu\/en-us\/sitemap.xml",
        "route": "mobile-parking-payments\/ecu\/en-us\/sitemap.xml",
        "params": []
    },
    "ser-ticket\/download-pdf": {
        "original_route": "GET ecu\/en-us\/ticket\/<idSERTicket:\\w+>.pdf",
        "route": "mobile-parking-payments\/ecu\/en-us\/ticket\/:idSERTicket.pdf",
        "params": [
            "idSERTicket"
        ]
    }
};