// be careful!
// generated file with Yii routes with webpack. Don't update lines here!
export const json = {
    "usa\/site\/index": {
        "original_route": "usa",
        "route": "mobile-parking-payments",
        "params": []
    },
    "site\/error": {
        "original_route": "usa\/error",
        "route": "mobile-parking-payments\/error",
        "params": []
    },
    "usa\/sitemap\/lang": {
        "original_route": "usa\/en-us\/sitemap.xml",
        "route": "mobile-parking-payments\/en-us\/sitemap.xml",
        "params": []
    },
    "usa\/sitemap\/index": {
        "original_route": "usa\/sitemap.xml",
        "route": "mobile-parking-payments\/sitemap.xml",
        "params": []
    },
    "site\/stripe-fallback": {
        "original_route": "usa\/stp",
        "route": "mobile-parking-payments\/stp",
        "params": []
    },
    "parking\/show-deep-link": {
        "original_route": "usa\/parking-detail",
        "route": "mobile-parking-payments\/parking-detail",
        "params": []
    },
    "account\/on-boarding": {
        "original_route": "usa\/complete-profile",
        "route": "mobile-parking-payments\/complete-profile",
        "params": []
    },
    "on-boarding\/redirect": {
        "original_route": "usa\/on-boarding-ok",
        "route": "mobile-parking-payments\/on-boarding-ok",
        "params": []
    },
    "user\/user\/create": {
        "original_route": "POST usa\/user",
        "route": "mobile-parking-payments\/user",
        "params": []
    },
    "user\/user\/update": {
        "original_route": "PUT usa\/user\/<id:\\d+>",
        "route": "mobile-parking-payments\/user\/:id",
        "params": [
            "id"
        ]
    },
    "user\/user\/update-basic-location": {
        "original_route": "PUT usa\/user-basic\/<id:\\d+>",
        "route": "mobile-parking-payments\/user-basic\/:id",
        "params": [
            "id"
        ]
    },
    "account\/logout": {
        "original_route": "usa\/disconnect",
        "route": "mobile-parking-payments\/disconnect",
        "params": []
    },
    "user\/account\/request-delete-user": {
        "original_route": "PUT usa\/remove-user",
        "route": "mobile-parking-payments\/remove-user",
        "params": []
    },
    "user\/account\/delete": {
        "original_route": "usa\/remove-user\/<token>",
        "route": "mobile-parking-payments\/remove-user\/:token",
        "params": [
            "token"
        ]
    },
    "account\/edit": {
        "original_route": "usa\/my-account",
        "route": "mobile-parking-payments\/my-account",
        "params": []
    },
    "account\/user": {
        "original_route": "usa\/my-details",
        "route": "mobile-parking-payments\/my-details",
        "params": []
    },
    "account\/security": {
        "original_route": "usa\/security",
        "route": "mobile-parking-payments\/security",
        "params": []
    },
    "user\/account\/social-login": {
        "original_route": "usa\/account\/social-login\/<social_network_name>",
        "route": "mobile-parking-payments\/account\/social-login\/:social_network_name",
        "params": [
            "social_network_name"
        ]
    },
    "user\/account\/password-reset": {
        "original_route": "POST usa\/recover-password",
        "route": "mobile-parking-payments\/recover-password",
        "params": []
    },
    "account\/request-password-reset": {
        "original_route": "usa\/recover-password",
        "route": "mobile-parking-payments\/recover-password",
        "params": []
    },
    "user\/account\/reset-password-update": {
        "original_route": "POST usa\/change-password\/<token>",
        "route": "mobile-parking-payments\/change-password\/:token",
        "params": [
            "token"
        ]
    },
    "user\/account\/reset-password": {
        "original_route": "usa\/change-password\/<token>",
        "route": "mobile-parking-payments\/change-password\/:token",
        "params": [
            "token"
        ]
    },
    "user\/account\/email-update-request": {
        "original_route": "usa\/email-change-request",
        "route": "mobile-parking-payments\/email-change-request",
        "params": []
    },
    "account\/update-email": {
        "original_route": "usa\/change-email\/<token>\/<newEmail>",
        "route": "mobile-parking-payments\/change-email\/:token\/:newEmail",
        "params": [
            "token",
            "newEmail"
        ]
    },
    "user\/account\/confirm": {
        "original_route": "usa\/change-email-confirm\/<code>",
        "route": "mobile-parking-payments\/change-email-confirm\/:code",
        "params": [
            "code"
        ]
    },
    "account\/login": {
        "original_route": "usa\/connect",
        "route": "mobile-parking-payments\/connect",
        "params": []
    },
    "account\/register": {
        "original_route": "usa\/register",
        "route": "mobile-parking-payments\/register",
        "params": []
    },
    "user\/user\/change-password": {
        "original_route": "usa\/change-password",
        "route": "mobile-parking-payments\/change-password",
        "params": []
    },
    "glovie\/preferences": {
        "original_route": "GET usa\/preferences",
        "route": "mobile-parking-payments\/preferences",
        "params": []
    },
    "user-preferences\/update": {
        "original_route": "PUT usa\/preferences\/<id:\\d+>",
        "route": "mobile-parking-payments\/preferences\/:id",
        "params": [
            "id"
        ]
    },
    "glovie\/privacy": {
        "original_route": "GET usa\/privacy",
        "route": "mobile-parking-payments\/privacy",
        "params": []
    },
    "user\/privacy\/create-update": {
        "original_route": "PUT usa\/privacy",
        "route": "mobile-parking-payments\/privacy",
        "params": []
    },
    "account\/validate": {
        "original_route": "usa\/validate\/<token>",
        "route": "mobile-parking-payments\/validate\/:token",
        "params": [
            "token"
        ]
    },
    "season-ticket\/contract": {
        "original_route": "GET usa\/subscription\/<seasonTicketId:\\d+>\/get",
        "route": "mobile-parking-payments\/subscription\/:seasonTicketId\/get",
        "params": [
            "seasonTicketId"
        ]
    },
    "season-ticket-contract\/show-detail": {
        "original_route": "GET usa\/subscription\/payment\/<id:\\d+>\/details",
        "route": "mobile-parking-payments\/subscription\/payment\/:id\/details",
        "params": [
            "id"
        ]
    },
    "season-ticket-contract\/show": {
        "original_route": "GET usa\/subscription\/payment\/<id:\\d+>\/view",
        "route": "mobile-parking-payments\/subscription\/payment\/:id\/view",
        "params": [
            "id"
        ]
    },
    "season-ticket-contract\/new": {
        "original_route": "GET usa\/subscription\/payment\/<id:\\d+>\/new",
        "route": "mobile-parking-payments\/subscription\/payment\/:id\/new",
        "params": [
            "id"
        ]
    },
    "glovie\/list-actives": {
        "original_route": "GET usa\/glovie\/list-actives",
        "route": "mobile-parking-payments\/glovie\/list-actives",
        "params": []
    },
    "glovie\/list-inactives": {
        "original_route": "GET usa\/glovie\/list-inactives\/<page:\\d+>",
        "route": "mobile-parking-payments\/glovie\/list-inactives\/:page",
        "params": [
            "page"
        ]
    },
    "season-ticket-contract\/create": {
        "original_route": "POST usa\/subscription",
        "route": "mobile-parking-payments\/subscription",
        "params": []
    },
    "season-ticket-contract\/update-vehicle": {
        "original_route": "PUT usa\/subscription\/<contractId:\\d+>\/vehicle\/<vehicleId:\\d+>",
        "route": "mobile-parking-payments\/subscription\/:contractId\/vehicle\/:vehicleId",
        "params": [
            "contractId",
            "vehicleId"
        ]
    },
    "api\/season-ticket-contract\/cancel": {
        "original_route": "DELETE usa\/subscription\/<idContract:\\d+>",
        "route": "mobile-parking-payments\/subscription\/:idContract",
        "params": [
            "idContract"
        ]
    },
    "season-ticket-contract\/create-and-pay-with-wallet": {
        "original_route": "POST usa\/pay-season-ticket-contract\/wallet\/<idWallet:\\d+>",
        "route": "mobile-parking-payments\/pay-season-ticket-contract\/wallet\/:idWallet",
        "params": [
            "idWallet"
        ]
    },
    "booking\/process": {
        "original_route": "GET usa\/confirm-booking\/<idPrebooking:\\d+>",
        "route": "mobile-parking-payments\/confirm-booking\/:idPrebooking",
        "params": [
            "idPrebooking"
        ]
    },
    "booking\/show": {
        "original_route": "GET usa\/reservation\/<idBooking:\\d+>\/view",
        "route": "mobile-parking-payments\/reservation\/:idBooking\/view",
        "params": [
            "idBooking"
        ]
    },
    "booking\/new": {
        "original_route": "GET usa\/reservation\/<idBooking:\\d+>\/new",
        "route": "mobile-parking-payments\/reservation\/:idBooking\/new",
        "params": [
            "idBooking"
        ]
    },
    "booking\/cancel": {
        "original_route": "PUT usa\/reservation\/cancel\/<idBooking:\\d+>",
        "route": "mobile-parking-payments\/reservation\/cancel\/:idBooking",
        "params": [
            "idBooking"
        ]
    },
    "booking\/pay-with-wallet": {
        "original_route": "PUT usa\/pay-booking\/<idBooking:\\d+>\/wallet\/<idWallet:\\d+>",
        "route": "mobile-parking-payments\/pay-booking\/:idBooking\/wallet\/:idWallet",
        "params": [
            "idBooking",
            "idWallet"
        ]
    },
    "wallet\/list": {
        "original_route": "GET usa\/wallet\/list",
        "route": "mobile-parking-payments\/wallet\/list",
        "params": []
    },
    "wallet\/payment-method": {
        "original_route": "usa\/wallet\/<idWallet:\\d+>\/payment-method",
        "route": "mobile-parking-payments\/wallet\/:idWallet\/payment-method",
        "params": [
            "idWallet"
        ]
    },
    "wallet\/my-wallet": {
        "original_route": "usa\/wallet",
        "route": "mobile-parking-payments\/wallet",
        "params": []
    },
    "wallet\/show": {
        "original_route": "usa\/wallet\/<idWallet:\\d+>\/show",
        "route": "mobile-parking-payments\/wallet\/:idWallet\/show",
        "params": [
            "idWallet"
        ]
    },
    "wallet\/beneficiaries": {
        "original_route": "usa\/wallet\/<idWallet:\\d+>\/beneficiaries",
        "route": "mobile-parking-payments\/wallet\/:idWallet\/beneficiaries",
        "params": [
            "idWallet"
        ]
    },
    "glovie\/wallet": {
        "original_route": "usa\/wallets",
        "route": "mobile-parking-payments\/wallets",
        "params": []
    },
    "wallet\/pay-debt": {
        "original_route": "PUT usa\/wallet\/pay-debt",
        "route": "mobile-parking-payments\/wallet\/pay-debt",
        "params": []
    },
    "wallet\/create-deposit": {
        "original_route": "POST usa\/wallet\/top-up",
        "route": "mobile-parking-payments\/wallet\/top-up",
        "params": []
    },
    "wallet\/associate-credit-card": {
        "original_route": "POST usa\/wallet\/<idWallet:\\d+>\/associate-credit-card\/<idCreditCard:\\d+>",
        "route": "mobile-parking-payments\/wallet\/:idWallet\/associate-credit-card\/:idCreditCard",
        "params": [
            "idWallet",
            "idCreditCard"
        ]
    },
    "wallet-preferences\/index": {
        "original_route": "GET usa\/wallet-preferences",
        "route": "mobile-parking-payments\/wallet-preferences",
        "params": []
    },
    "wallet-preferences\/update": {
        "original_route": "PUT usa\/wallet-preferences\/<id:\\d+>",
        "route": "mobile-parking-payments\/wallet-preferences\/:id",
        "params": [
            "id"
        ]
    },
    "credit-card\/new": {
        "original_route": "POST usa\/credit-card",
        "route": "mobile-parking-payments\/credit-card",
        "params": []
    },
    "credit-card\/delete": {
        "original_route": "DELETE usa\/credit-card\/<id:\\d+>",
        "route": "mobile-parking-payments\/credit-card\/:id",
        "params": [
            "id"
        ]
    },
    "credit-card\/create": {
        "original_route": "POST usa\/wallet\/<idWallet:\\d+>\/credit-card",
        "route": "mobile-parking-payments\/wallet\/:idWallet\/credit-card",
        "params": [
            "idWallet"
        ]
    },
    "credit-card\/list-by-country": {
        "original_route": "GET usa\/credit-card\/list\/<countryId:\\d+>",
        "route": "mobile-parking-payments\/credit-card\/list\/:countryId",
        "params": [
            "countryId"
        ]
    },
    "glovie\/vehicles": {
        "original_route": "usa\/my-cars",
        "route": "mobile-parking-payments\/my-cars",
        "params": []
    },
    "glovie\/vehicles-page": {
        "original_route": "usa\/my-cars\/<page:\\d+>",
        "route": "mobile-parking-payments\/my-cars\/:page",
        "params": [
            "page"
        ]
    },
    "glovie\/trailers": {
        "original_route": "usa\/my-trailers",
        "route": "mobile-parking-payments\/my-trailers",
        "params": []
    },
    "glovie\/trailers-page": {
        "original_route": "usa\/my-trailers\/<page:\\d+>",
        "route": "mobile-parking-payments\/my-trailers\/:page",
        "params": [
            "page"
        ]
    },
    "vehicle-brand\/vehicle-models": {
        "original_route": "GET usa\/vehicle-brand\/<brandId:\\d+>\/models",
        "route": "mobile-parking-payments\/vehicle-brand\/:brandId\/models",
        "params": [
            "brandId"
        ]
    },
    "vehicle\/index": {
        "original_route": "GET usa\/vehicle",
        "route": "mobile-parking-payments\/vehicle",
        "params": []
    },
    "vehicle\/view": {
        "original_route": "GET usa\/vehicle\/<vehicleid:\\d+>",
        "route": "mobile-parking-payments\/vehicle\/:vehicleid",
        "params": [
            "vehicleid"
        ]
    },
    "vehicle\/delete": {
        "original_route": "DELETE usa\/vehicle\/<vehicleId:\\d+>",
        "route": "mobile-parking-payments\/vehicle\/:vehicleId",
        "params": [
            "vehicleId"
        ]
    },
    "vehicle\/create": {
        "original_route": "POST usa\/vehicle",
        "route": "mobile-parking-payments\/vehicle",
        "params": []
    },
    "vehicle\/update": {
        "original_route": "PUT usa\/vehicle\/<vehicleId:\\d+>",
        "route": "mobile-parking-payments\/vehicle\/:vehicleId",
        "params": [
            "vehicleId"
        ]
    },
    "offered-parking-pass\/contract": {
        "original_route": "GET usa\/parking\/<idParking:\\d+>\/parking-pass\/<id:\\d+>",
        "route": "mobile-parking-payments\/parking\/:idParking\/parking-pass\/:id",
        "params": [
            "idParking",
            "id"
        ]
    },
    "booked-parking-pass\/new": {
        "original_route": "GET usa\/parking-pass\/<id:\\d+>\/new",
        "route": "mobile-parking-payments\/parking-pass\/:id\/new",
        "params": [
            "id"
        ]
    },
    "booked-parking-pass\/show": {
        "original_route": "GET usa\/parking-pass\/<id:\\d+>\/show",
        "route": "mobile-parking-payments\/parking-pass\/:id\/show",
        "params": [
            "id"
        ]
    },
    "user-preferences\/auto-deposit": {
        "original_route": "usa\/auto-deposit",
        "route": "mobile-parking-payments\/auto-deposit",
        "params": []
    },
    "invoice\/pdf": {
        "original_route": "GET usa\/billing\/invoices\/<invoiceUid>.pdf",
        "route": "mobile-parking-payments\/billing\/invoices\/:invoiceUid.pdf",
        "params": [
            "invoiceUid"
        ]
    },
    "invoice\/xlsx": {
        "original_route": "GET usa\/billing\/invoices\/<invoiceUid>.xlsx",
        "route": "mobile-parking-payments\/billing\/invoices\/:invoiceUid.xlsx",
        "params": [
            "invoiceUid"
        ]
    },
    "invoice\/view-xlsx": {
        "original_route": "GET usa\/download-billing\/<invoiceUid>.xlsx",
        "route": "mobile-parking-payments\/download-billing\/:invoiceUid.xlsx",
        "params": [
            "invoiceUid"
        ]
    },
    "invoice\/list": {
        "original_route": "GET usa\/billing\/invoices",
        "route": "mobile-parking-payments\/billing\/invoices",
        "params": []
    },
    "invoice\/page": {
        "original_route": "GET usa\/billing\/invoices\/<page:\\d+>",
        "route": "mobile-parking-payments\/billing\/invoices\/:page",
        "params": [
            "page"
        ]
    },
    "invoice\/update": {
        "original_route": "PUT usa\/billing\/data",
        "route": "mobile-parking-payments\/billing\/data",
        "params": []
    },
    "usa\/site\/landing-enterprise": {
        "original_route": "usa\/companies",
        "route": "mobile-parking-payments\/companies",
        "params": []
    },
    "parking\/list": {
        "original_route": "usa\/search-for-parking-space",
        "route": "mobile-parking-payments\/search-for-parking-space",
        "params": []
    },
    "parking\/show": {
        "original_route": "usa\/<province>\/<slug>\/<idParking:\\d+>",
        "route": "mobile-parking-payments\/:province\/:slug\/:idParking",
        "params": [
            "province",
            "slug",
            "idParking"
        ]
    },
    "site\/terms": {
        "original_route": "usa\/legal-terms",
        "route": "mobile-parking-payments\/legal-terms",
        "params": []
    },
    "site\/terms-cookies": {
        "original_route": "usa\/privacy-policy-cookies",
        "route": "mobile-parking-payments\/privacy-policy-cookies",
        "params": []
    },
    "user\/account\/unsubscribe-from-commercials": {
        "original_route": "usa\/unsubscribe\/<email>",
        "route": "mobile-parking-payments\/unsubscribe\/:email",
        "params": [
            "email"
        ]
    },
    "site\/landing-merchant-how-it-works": {
        "original_route": "usa\/redeem-coupons",
        "route": "mobile-parking-payments\/redeem-coupons",
        "params": []
    },
    "site\/landing-merchant": {
        "original_route": "usa\/merchant",
        "route": "mobile-parking-payments\/merchant",
        "params": []
    },
    "merchant\/index": {
        "original_route": "usa\/merchant\/account",
        "route": "mobile-parking-payments\/merchant\/account",
        "params": []
    },
    "merchant\/shop": {
        "original_route": "usa\/merchant\/shop",
        "route": "mobile-parking-payments\/merchant\/shop",
        "params": []
    },
    "merchant\/shop-step": {
        "original_route": "usa\/merchant\/shop\/step<id:\\d+>",
        "route": "mobile-parking-payments\/merchant\/shop\/step:id",
        "params": [
            "id"
        ]
    },
    "coupon-order\/pdf": {
        "original_route": "usa\/merchant\/order\/<id:\\d+>.pdf",
        "route": "mobile-parking-payments\/merchant\/order\/:id.pdf",
        "params": [
            "id"
        ]
    },
    "merchant\/order": {
        "original_route": "usa\/merchant\/order\/<id:\\d+>\/detail",
        "route": "mobile-parking-payments\/merchant\/order\/:id\/detail",
        "params": [
            "id"
        ]
    },
    "merchant\/sample-coupon": {
        "original_route": "usa\/merchant\/sample-coupon",
        "route": "mobile-parking-payments\/merchant\/sample-coupon",
        "params": []
    },
    "poi\/show": {
        "original_route": "usa\/park-in-<townSlug>\/parking-<poiSlug>",
        "route": "mobile-parking-payments\/park-in-:townSlug\/parking-:poiSlug",
        "params": [
            "townSlug",
            "poiSlug"
        ]
    },
    "poi\/show-events": {
        "original_route": "usa\/events-in-<poiId>\/<month:\\d+>",
        "route": "mobile-parking-payments\/events-in-:poiId\/:month",
        "params": [
            "poiId",
            "month"
        ]
    },
    "town\/show": {
        "original_route": "usa\/park-in-<slug>",
        "route": "mobile-parking-payments\/park-in-:slug",
        "params": [
            "slug"
        ]
    },
    "fine-portal\/index": {
        "original_route": "usa\/fines-portal\/<townId>",
        "route": "mobile-parking-payments\/fines-portal\/:townId",
        "params": [
            "townId"
        ]
    },
    "fine-portal\/fine": {
        "original_route": "usa\/parkontrol\/<townId>\/fine\/<bulletinNumber:\\d+>",
        "route": "mobile-parking-payments\/parkontrol\/:townId\/fine\/:bulletinNumber",
        "params": [
            "townId",
            "bulletinNumber"
        ]
    },
    "fine-portal\/slug-with-bulletin": {
        "original_route": "usa\/parkontrol-<slug>\/<bulletinNumber:\\d+>",
        "route": "mobile-parking-payments\/parkontrol-:slug\/:bulletinNumber",
        "params": [
            "slug",
            "bulletinNumber"
        ]
    },
    "fine-portal\/slug": {
        "original_route": "usa\/parkontrol-<slug>",
        "route": "mobile-parking-payments\/parkontrol-:slug",
        "params": [
            "slug"
        ]
    },
    "fine-portal\/portal": {
        "original_route": "usa\/parkontrol",
        "route": "mobile-parking-payments\/parkontrol",
        "params": []
    },
    "fine-portal\/portal-with-town": {
        "original_route": "usa\/parkontrol\/<townId>",
        "route": "mobile-parking-payments\/parkontrol\/:townId",
        "params": [
            "townId"
        ]
    },
    "wallet-corporate-plates-whitelist\/download": {
        "original_route": "usa\/plates-whitelist\/<walletId:\\d+>\/download",
        "route": "mobile-parking-payments\/plates-whitelist\/:walletId\/download",
        "params": [
            "walletId"
        ]
    },
    "api\/wallet-corporate\/download-file": {
        "original_route": "GET usa\/wallets\/<walletId:\\d+>\/download-movements-csv\/<file>",
        "route": "mobile-parking-payments\/wallets\/:walletId\/download-movements-csv\/:file",
        "params": [
            "walletId",
            "file"
        ]
    }
};