// be careful!
// generated file with Yii routes with webpack. Don't update lines here!
export const json = {
    "col\/site\/index": {
        "original_route": "col\/en-us",
        "route": "mobile-parking-payments\/col\/en-us",
        "params": []
    },
    "col\/sitemap\/lang": {
        "original_route": "col\/en-us\/sitemap.xml",
        "route": "mobile-parking-payments\/col\/en-us\/sitemap.xml",
        "params": []
    },
    "site\/error": {
        "original_route": "col\/en-us\/error",
        "route": "mobile-parking-payments\/col\/en-us\/error",
        "params": []
    },
    "site\/terms": {
        "original_route": "col\/en-us\/legal-terms",
        "route": "mobile-parking-payments\/col\/en-us\/legal-terms",
        "params": []
    },
    "site\/terms-cookies": {
        "original_route": "col\/en-us\/privacy-policy-cookies",
        "route": "mobile-parking-payments\/col\/en-us\/privacy-policy-cookies",
        "params": []
    },
    "user\/account\/password-reset": {
        "original_route": "POST col\/en-us\/recover-password",
        "route": "mobile-parking-payments\/col\/en-us\/recover-password",
        "params": []
    },
    "account\/request-password-reset": {
        "original_route": "col\/en-us\/recover-password",
        "route": "mobile-parking-payments\/col\/en-us\/recover-password",
        "params": []
    },
    "user\/account\/reset-password-update": {
        "original_route": "POST col\/en-us\/change-password\/<token>",
        "route": "mobile-parking-payments\/col\/en-us\/change-password\/:token",
        "params": [
            "token"
        ]
    },
    "user\/account\/reset-password": {
        "original_route": "col\/en-us\/change-password\/<token>",
        "route": "mobile-parking-payments\/col\/en-us\/change-password\/:token",
        "params": [
            "token"
        ]
    },
    "user\/account\/email-update-request": {
        "original_route": "col\/en-us\/email-change-request",
        "route": "mobile-parking-payments\/col\/en-us\/email-change-request",
        "params": []
    },
    "account\/update-email": {
        "original_route": "col\/en-us\/change-email\/<token>\/<newEmail>",
        "route": "mobile-parking-payments\/col\/en-us\/change-email\/:token\/:newEmail",
        "params": [
            "token",
            "newEmail"
        ]
    },
    "user\/account\/confirm": {
        "original_route": "col\/en-us\/change-email-confirm\/<code>",
        "route": "mobile-parking-payments\/col\/en-us\/change-email-confirm\/:code",
        "params": [
            "code"
        ]
    },
    "account\/validate": {
        "original_route": "col\/en-us\/validate\/<token>",
        "route": "mobile-parking-payments\/col\/en-us\/validate\/:token",
        "params": [
            "token"
        ]
    },
    "user\/account\/request-delete-user": {
        "original_route": "PUT col\/en-us\/remove-user",
        "route": "mobile-parking-payments\/col\/en-us\/remove-user",
        "params": []
    },
    "user\/account\/delete": {
        "original_route": "col\/en-us\/remove-user\/<token>",
        "route": "mobile-parking-payments\/col\/en-us\/remove-user\/:token",
        "params": [
            "token"
        ]
    }
};