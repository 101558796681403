// be careful!
// generated file with Yii routes with webpack. Don't update lines here!
export const json = {
    "ecu\/site\/index": {
        "original_route": "ecu",
        "route": "mobile-parking-payments\/ecu",
        "params": []
    },
    "ecu\/site\/home-redirect": {
        "original_route": "ecu\/es-ec",
        "route": "mobile-parking-payments\/ecu\/es-ec",
        "params": []
    },
    "ecu\/sitemap\/lang": {
        "original_route": "ecu\/es-ec\/sitemap.xml",
        "route": "mobile-parking-payments\/ecu\/es-ec\/sitemap.xml",
        "params": []
    },
    "site\/error": {
        "original_route": "ecu\/error",
        "route": "mobile-parking-payments\/ecu\/error",
        "params": []
    },
    "site\/terms": {
        "original_route": "ecu\/terminos-legales",
        "route": "mobile-parking-payments\/ecu\/terminos-legales",
        "params": []
    },
    "ser-ticket\/download-pdf": {
        "original_route": "GET ecu\/ticket\/<idSERTicket:\\w+>.pdf",
        "route": "mobile-parking-payments\/ecu\/ticket\/:idSERTicket.pdf",
        "params": [
            "idSERTicket"
        ]
    }
};