import UrlTo from 'commons/js/util/UrlTo'
import { ZENDESK_URL } from 'commons/js/constants'
import defaultLanguage from 'commons/js/locale'

export const getZendeskUrl = (lang) => {
    const zendeskLang = lang
        ? lang.toLowerCase()
        : defaultLanguage.toLowerCase()
    return ZENDESK_URL + zendeskLang
}

const Routes = {
    privacyPath (params) {
        return UrlTo.get('glovie/privacy', params)
    },
    preferencesPath (params) {
        return UrlTo.get('glovie/preferences', params)
    },
    securityPath (params) {
        return UrlTo.get('account/security', params)
    },
    mobesPath (params) {
        return UrlTo.get('mobe/page', params)
    },
    tollsPath (params) {
        return UrlTo.get('toll/page', params)
    },
    homePath (params, language, country, flavor) {
        return UrlTo.get('site/index', params, language, country, flavor)
    },
    homeEnterprisePath (params, language, country) {
        return UrlTo.get('site/landing-enterprise', params, language, country)
    },
    getHomeViaTPath (params, language, country) {
        return UrlTo.get('site/viat', params, language, country)
    },
    getHomeWorkshopsPath (params, language, country) {
        return UrlTo.get('site/landing-workshop', params, language, country)
    },
    finePortalPath () {
        return UrlTo.get('fine-portal/index')
    },
    finePortalAtlantaPath (params) {
        return UrlTo.get('fine-portal/atlanta', params)
    },
    finePortalWithTown(params) {
        return UrlTo.get('fine-portal/portal-with-town', params)
    },
    finePortalWithFinePath (params) {
        return UrlTo.get('fine-portal/index', params)
    },

    loginPath (params) {
        return UrlTo.get('account/login', params)
    },

    requestPasswordPath (params) {
        return UrlTo.get('account/request-password-reset', params)
    },

    termsAndConditionsPath (params, lang, country) {
        return UrlTo.get('site/terms', params, lang, country)
    },

    cookiesPath (params, lang, country) {
        return UrlTo.get('site/terms-cookies', params, lang, country)
    },

    zendeskPath (lang) {
        return getZendeskUrl(lang)
    },

    zendeskNewRequest (lang) {
        return getZendeskUrl(lang) + '/requests/new'
    },

    zendeskViatDeviceInstructions (lang) {
        return getZendeskUrl(lang) + '/articles/115000188950-C%C3%B3mo-debo-colocar-mi-dispositivo-Via-T'
    },

    zendeskViatDeviceNotWorking (lang) {
        return getZendeskUrl(lang) + '/articles/115000196424-Mi-Via-T-no-funciona-y-emite-dos-pitidos-Está-bloqueado'
    },

    viatZendeskPath (lang) {
        return getZendeskUrl(lang) + '/sections/115000077190-Via-T'
    },

    onBoardingPath (params) {
        return UrlTo.get('account/on-boarding', params)
    },

    parkingPath (params) {
        return UrlTo.get('parking/show', params)
    },

    vehicleInspectionStationPath (params) {
        return UrlTo.get('vehicle-inspection-station/show', params)
    },

    poiEventsPath (params) {
        return UrlTo.get('poi/show-events', params)
    },

    parkingsSearchPath (params) {
        return UrlTo.get('parking/list', params)
    },

    vehicleInspectionStationSearchPath (params) {
        return UrlTo.get('vehicle-inspection-station/list', params)
    },

    chargingPointSearchPath (params) {
        return UrlTo.get('charging-point/list', params)
    },

    bookingProcessPath (params) {
        return UrlTo.get('booking/process', params)
    },

    workshopBookingSelectionPath (params) {
        return UrlTo.get('workshop-booking/selection', params)
    },

    getNumberPlatesWhitelistFile (params) {
        return UrlTo.get('wallet-corporate-plates-whitelist/download', params)
    },

    apiWorkshopBookingUpdateProductPath (params) {
        return UrlTo.getApi('api/workshop-booking/assign-products', params)
    },

    apiWorkshopBookingUpdateDates (params) {
        return UrlTo.getApi('api/workshop-booking/set-appointment-date', params)
    },

    apiWorkshopBookingAssignWorkshopPath (params) {
        return UrlTo.getApi('api/workshop-booking/assign-workshop', params)
    },

    apiCreateWorkshopBooking (params) {
        return UrlTo.getApi('api/workshop-booking/create', params)
    },

    apiConfirmWorkshopBooking (params) {
        return UrlTo.getApi('api/workshop-booking/confirm-appointment', params)
    },

    workshopBookingProcessPath (params) {
        return UrlTo.get('workshop-booking/process', params)
    },

    newWorkshopBookingPath (params) {
        return UrlTo.get('workshop-booking/new', params)
    },

    apiGetWorkshopBookingOccupation (params) {
        return UrlTo.getApi('api/workshops/availability', params)
    },

    viatLadingHowItsWorksPath () {
        return UrlTo.get('site/viat-how-it-works')
    },

    viatInvoiceDownload (params) {
        return UrlTo.get('shop/shop/invoice', params)
    },

    viatShopPath (params) {
        return UrlTo.get('viat-shop/shop', params)
    },

    vehicleInspectionBookingProcessPath (params) {
        return UrlTo.get('vehicle-inspection-booking/process', params)
    },

    vehicleInspectionBookingNewPath (params) {
        return UrlTo.get('vehicle-inspection-booking/new', params)
    },

    newBookingPath (params) {
        return UrlTo.get('booking/new', params)
    },

    viatNewOrderPath (params) {
        return UrlTo.get('viat-shop/new', params)
    },

    walletInvoiceData (params) {
        return UrlTo.get('wallet/data', params)
    },

    invoicePagePath (params) {
        return UrlTo.get('invoice/page', params)
    },

    serTicketShowPage (params) {
        return UrlTo.get('ser-ticket/show-detail', params)
    },

    serTicketSend (params) {
        return UrlTo.get('ser-ticket/send-email', params)
    },

    rotationShowPage (params) {
        return UrlTo.get('glovie/show-rotation', params)
    },

    workshopBookingShowPage (params) {
        return UrlTo.get('glovie/show-workshop-booking', params)
    },

    bookingShowPage (params) {
        return UrlTo.get('booking/show', params)
    },

    vehicleInspectionBookingPath (params) {
        return UrlTo.get('glovie/show-itv-booking', params)
    },

    pagateliaOrderPath (params) {
        return UrlTo.get('glovie/pagatelia-order', params)
    },

    pagateliaOrdersPath (params) {
        return UrlTo.get('order/page', params)
    },

    walletPath (params) {
        return UrlTo.get('glovie/wallet', params)
    },

    walletDetailPath (params) {
        return UrlTo.get('wallet/show', params)
    },

    glovieWalletBeneficiariesPath (params) {
        return UrlTo.get('wallet/beneficiaries', params)
    },

    chargingPointBookingPath (params) {
        return UrlTo.get('glovie/show-charging-point-booking', params)
    },

    parkingPassShowPage (params) {
        return UrlTo.get('booked-parking-pass/show', params)
    },

    seasonTicketShowPage (params) {
        return UrlTo.get('season-ticket-contract/show', params)
    },

    glovieActivesPath (params) {
        return UrlTo.get('glovie/list-actives', params)
    },

    glovieInactivesPath (params) {
        return UrlTo.get('glovie/list-inactives', params)
    },

    glovieVehiclesPath (params) {
        return UrlTo.get('glovie/vehicles-page', params)
    },

    glovieTrailersPath (params) {
        return UrlTo.get('glovie/trailers-page', params)
    },

    couponSamplePath (params) {
        return UrlTo.get('merchant/sample-coupon', params)
    },

    showMerchantCouponOrder (params) {
        return UrlTo.get('merchant/order', params)
    },

    apiBookingPath (params) {
        return UrlTo.getApi('api/booking/view', params)
    },

    apiWorkshopBookingPath (params) {
        return UrlTo.getApi('api/workshop-booking/get', params)
    },

    apiSuitableBookingWorkshops (params) {
        return UrlTo.getApi('api/workshops/index', params)
    },

    apiRotationPath (params) {
        return UrlTo.getApi('api/rotation/view', params)
    },

    apiCancelBookingPath (params) {
        return UrlTo.getApi('api/booking/cancel', params)
    },

    apiCancelWorkshopBookingPath (params) {
        return UrlTo.getApi('api/workshop-booking/cancel', params)
    },

    apiCancelVehicleInspectionBookingPath (params) {
        return UrlTo.getApi('api/vehicle-inspection-booking/cancel', params)
    },

    apiCancelChargingPointBookingPath (params) {
        return UrlTo.getApi('api/charging-point-bookings/cancel', params)
    },

    apiSearchParkings (params) {
        return UrlTo.getApi('api/parkings/index', params)
    },

    apiSearchAirportParkings (params) {
        return UrlTo.getApi('api/airports/parkings', params)
    },

    apiSearchAirports (params) {
        return UrlTo.getApi('api/airports/index', params)
    },

    apiSearchVehicleInspectionStation (params) {
        return UrlTo.getApi('api/vehicle-inspection-stations/index', params)
    },

    apiSearchChargingPoints (params) {
        return UrlTo.getApi('api/charging-points/index', params)
    },

    apiSearchPoisWithEvents (params) {
        return UrlTo.getApi('api/point-of-interest/list-with-events', params)
    },

    apiFetchAirport (params) {
        return UrlTo.getApi('api/airports/view', params)
    },

    apiBookingAvailability (params) {
        return UrlTo.getApi('api/parkings/booking-availability', params)
    },

    apiBookingProductsAvailability (params) {
        return UrlTo.getApi('api/parkings/booking-products-availability', params)
    },

    getIpCountry () {
        return UrlTo.getApi('api/country/geolocation-by-ip')
    },

    getAcceptedCountries () {
        return UrlTo.getApi('api/country/accepted-countries')
    },

    getTOS () {
        return UrlTo.getApi('api/gdpr-conditions/get-terms-to-approve')
    },

    acceptTOS () {
        return UrlTo.getApi('api/gdpr-conditions/approve')
    },

    setPrivacy () {
        return UrlTo.getApi('api/privacy/create-update')
    },

    apiGetParking (params) {
        return UrlTo.getApi('api/parkings/view', params)
    },

    login () {
        return UrlTo.getApi('api/account/login')
    },

    socialLogin () {
        return UrlTo.getApi('api/account/social-login')
    },

    apiLogout () {
        return UrlTo.getApi('api/user/log-out')
    },

    apiGetUser () {
        return UrlTo.getApi('api/user/show')
    },

    apiGetUserWallets () {
        return UrlTo.getApi('api/wallet/user-wallets')
    },

    apiCheckUserExists (params) {
        return UrlTo.getApi('api/user/check-user-exists', params)
    },

    apiCreateNewUserBooking () {
        return UrlTo.getApi('api/booking/create-new-user-booking')
    },

    apiCreateExistingUserBooking () {
        return UrlTo.getApi('api/booking/create-logged-user-booking')
    },

    apiGetVehicles () {
        return UrlTo.getApi('api/vehicles/index')
    },

    apiGetPagedVehicles (params) {
        return UrlTo.getApi('api/vehicles/paginated', params)
    },

    apiUpdateVehicle (params) {
        return UrlTo.getApi('api/vehicles/update', params)
    },

    apiDeleteVehicle (params) {
        return UrlTo.getApi('api/vehicles/delete', params)
    },

    apiCreateVehicle (params) {
        return UrlTo.getApi('api/vehicles/create', params)
    },

    apiGetPagedTrailers (params) {
        return UrlTo.getApi('api/trailers/paginated', params)
    },

    apiDeleteTrailer (params) {
        return UrlTo.getApi('api/trailers/delete', params)
    },

    apiCreateTrailer (params) {
        return UrlTo.getApi('api/trailers/create', params)
    },

    apiRequestUserPricePerWallet (params) {
        return UrlTo.getApi('api/wallet-corporate/price-per-beneficiary', params)
    },

    apiCreateWalletBeneficiary (params) {
        return UrlTo.getApi('api/wallet-corporate/add-beneficiary', params)
    },

    apiDeleteWalletBeneficiary (params) {
        return UrlTo.getApi('api/wallet-corporate/remove-beneficiary', params)
    },

    apiCreateDeposit (params) {
        return UrlTo.getApi('api/wallet/create-deposit', params)
    },

    apiGetVehicleBrands (params) {
        return UrlTo.getApi('api/vehicles/brands', params)
    },

    apiGetVehicleColors () {
        return UrlTo.getApi('api/vehicles/colors')
    },

    apiCreatePrebooking () {
        return UrlTo.getApi('api/prebooking/create')
    },
    apiGetPrebooking (params) {
        return UrlTo.getApi('api/prebooking/fetch', params)
    },

    apiUpdatePrebooking (params) {
        return UrlTo.getApi('api/prebooking/update', params)
    },

    apiCreateAccount (params) {
        return UrlTo.getApi('api/account/create', params)
    },

    apiSendContactForm (params) {
        return UrlTo.getApi('api/contact/send-form', params)
    },

    apiCompleteProfile (params) {
        return UrlTo.getApi('api/user/on-boarding', params)
    },

    apiCreateSetupIntent (params) {
        return UrlTo.getApi('api/credit-card/create-setup-intent', params)
    },

    apiRequestInvoices (params) {
        return UrlTo.getApi('api/invoices/list', params)
    },

    apiRequestInvoiceXlsx (params) {
        return UrlTo.getApi('api/invoices/xlsx', params)
    },

    apiRequestProvinces (params) {
        return UrlTo.getApi('api/provinces/list', params)
    },

    apiGetProvincesForShop (params) {
        return UrlTo.getApi('api/provinces/list-for-shop', params)
    },

    apiRequestTownsByProvince (params) {
        return UrlTo.getApi('api/provinces/towns-by-province', params)
    },

    apiReportClaim (params) {
        return UrlTo.getApi('api/parking-fine-usa/notify-claim', params)
    },

    apiRequestWalletInvoiceData (params) {
        return UrlTo.getApi('api/wallet-invoice-data/view', params)
    },

    apiUpdateWalletInvoiceData (params) {
        return UrlTo.getApi('api/wallet-invoice-data/update', params)
    },

    apiRequestWalletData (params) {
        return UrlTo.getApi('api/wallet/fetch', params)
    },

    apiGetWalletBeneficiaries (params) {
        return UrlTo.getApi('api/wallet-corporate/beneficiaries-list', params)
    },

    apiGetWalletBeneficiariesAll (params) {
        return UrlTo.getApi('api/wallet-corporate/get-all-beneficiaries', params)
    },

    apiGetActiveContracts (params) {
        return UrlTo.getApi('api/contracts/index-by-user', params)
    },

    apiGetInactiveContracts (params) {
        return UrlTo.getApi('api/contracts/old-by-user', params)
    },

    apiCheckExpandedFine (params) {
        return UrlTo.getApi('api/parking-fine-usa/check-fine-with-extra-info', params)
    },

    apiPayExpandedFine (params) {
        return UrlTo.getApi('api/parking-fine-usa/cancel-parking-fine', params)
    },

    apiRequestVehicleInspectionBooking (params) {
        return UrlTo.getApi('api/vehicle-inspection-booking/view', params)
    },

    apiRequestChargingPointBooking (params) {
        return UrlTo.getApi('api/charging-point-bookings/view', params)
    },

    apiRequestVehicleInspectionProducts (params) {
        return UrlTo.getApi('api/vehicle-inspection-station-products/get-filtered-by-station', params)
    },

    apiGetVehicleInspectionStation (params) {
        return UrlTo.getApi('api/vehicle-inspection-stations/view', params)
    },

    apiGetVehicleInspectionStationOccupationForDays (params) {
        return UrlTo.getApi('api/vehicle-inspection-stations/occupation-for-days', params)
    },

    apiGetVehicleInspectionStationOccupationIntraDay (params) {
        return UrlTo.getApi('api/vehicle-inspection-stations/occupation-intra-day', params)
    },

    apiVehicleInspectionCreateNewUserBooking (params) {
        return UrlTo.getApi('api/vehicle-inspection-booking/create-new-user-booking', params)
    },

    apiVehicleInspectionCreateExistingUserBooking (params) {
        return UrlTo.getApi('api/vehicle-inspection-booking/create-logged-user-booking', params)
    },

    apiGetEnvironmentalLabelNumber (params) {
        return UrlTo.getApi('api/vehicles/get-environmental-label', params)
    },
    apiSavePreferences (params) {
        return UrlTo.getApi('api/user-preferences/update', params)
    },
    apiGetPrivacy () {
        return UrlTo.getApi('api/privacy/get')
    },
    apiGetViatParkingsByCountry (params) {
        return UrlTo.getApi('api/parkings/index-by-opening-mode-viat-and-country', params)
    },
    apiViatHighwayDiscounts () {
        return UrlTo.getApi('api/viat/highway-discounts')
    },
    apiGetCreditCards () {
        return UrlTo.getApi('api/credit-card/list')
    },
    apiGetCreditCardInfo (params) {
        return UrlTo.getApi('api/credit-card/info', params)
    },
    apiCreateCreditCard (params) {
        return UrlTo.getApi('api/credit-card/create', params)
    },
    apiDeleteCreditCard (params) {
        return UrlTo.getApi('api/credit-card/delete', params)
    },
    apiSetPagateliaOrderData (params) {
        return UrlTo.getApi('api/viat-order/update', params)
    },
    apiGetPagateliaOrderData (params) {
        return UrlTo.getApi('api/viat-order/get', params)
    },
    apiSendDownloadMovementsToEmail (params) {
        return UrlTo.getApi('api/wallet/request-movements-csv', params)
    },
    apiGetGlobalConfig () {
        return UrlTo.getApi('api/config/get')
    },
    apiGetFinePortalTowns (params) {
        return UrlTo.getApi('api/town/all-having-fines', params)
    },
    apiCreatePagateliaOrder (params) {
        return UrlTo.getApi('api/viat-order/create', params)
    },
    apiUpdatePagateliaOrder (params) {
        return UrlTo.getApi('api/viat-order/update', params)
    },
    apiGetViatProducts (params) {
        return UrlTo.getApi('api/viat-products/get-price', params)
    },
    apiPayNewUserViatOrder (params) {
        return UrlTo.getApi('api/viat-order/pay-new-user-order', params)
    },
    apiPayExistingUserViatOrder (params) {
        return UrlTo.getApi('api/viat-order/pay-logged-user-order', params)
    },
    apiConfirmPaymentIntent (params) {
        return UrlTo.getApi('api/wallet/confirm-payment-intent', params)
    },
    apiGetMobes (params) {
        return UrlTo.getApi('api/mobe/list', params)
    },
    apiGetTolls (params) {
        return UrlTo.getApi('api/toll/list', params)
    },
    apiGetEnabledMobes (params) {
        return UrlTo.getApi('api/mobe/list-enabled', params)
    },
    apiBindWalletToMobe (params) {
        return UrlTo.getApi('api/mobe/bind-wallet', params)
    },
    apiBookingUpdateVehicle (params) {
        return UrlTo.getApi('api/booking/update-vehicle', params)
    },
    apiBindVehicleToMobe (params) {
        return UrlTo.getApi('api/mobe/bind-vehicle', params)
    },
    apiUnbindVehicleToMobe (params) {
        return UrlTo.getApi('api/mobe/unbind-vehicle', params)
    },
    apiUnassignMobe (params) {
        return UrlTo.getApi('api/mobe/unassign', params)
    },
    apiGetPagateliaOrdersData (params) {
        return UrlTo.getApi('api/viat-order/list', params)
    },
    apiDeletePagateliaOrderData (params) {
        return UrlTo.getApi('api/viat-order/cancel', params)
    },
    apiGetTwoFaActiveMethod (params) {
        return UrlTo.getApi('api/two-factor-authentication/get-active-method', params)
    },
    apiVehicleUpdateKm (params) {
        return UrlTo.getApi('api/vehicles/update-km', params)
    },
    apiActiveTwoFaActiveEmailMethod (params) {
        return UrlTo.getApi('api/two-factor-authentication/activate-email-method', params)
    },
    apiCheckAdvancedVehiclesAdmin (params) {
        return UrlTo.getApi('api/user/check-advanced-vehicles-admin', params)
    },
    apiUploadVehicleCsvFile (params) {
        return UrlTo.getApi('api/vehicles/upload-number-plates-csv', params)
    },
    apiUploadTrailerCsvFile (params) {
        return UrlTo.getApi('api/trailers/upload-number-plates-csv', params)
    },
    apiUploadNumberPlateWhitelistCsvFile (params) {
        return UrlTo.getApi('api/wallet-corporate-plates-whitelist/upload-number-plates-csv', params)
    },
    apiUploadWalletBeneficiaryCsvFile (params) {
        return UrlTo.getApi('api/wallet-corporate/add-beneficiaries-from-csv', params)
    },
    apiDeactivateTwoFaActiveEmailMethod (params) {
        return UrlTo.getApi('api/two-factor-authentication/deactivate-email-method', params)
    },
    apiGetBookingAvailabilityUpdateDates (params) {
        return UrlTo.getApi('api/booking/availability-update-dates', params)
    },
    apiUpdateUserData (params) {
        return UrlTo.getApi('api/user/update', params)
    },
    updateBookingDates (params) {
        return UrlTo.getApi('api/booking/update-dates', params)
    },
    apiGetWalletPreferences (params) {
        return UrlTo.getApi('api/wallet-preferences/index', params)
    },
    apiAssociateCreditCard (params) {
        return UrlTo.getApi('api/wallet/associate-credit-card', params)
    },
    apiUpdateWalletPreferences (params) {
        return UrlTo.getApi('api/wallet-preferences/update', params)
    },
    apiUpdateNumberPlatesWhitelistEnabled (params) {
        return UrlTo.getApi('api/wallet/update-plates-whitelist-enabled', params)
    },
    apiGetPagedCorporateWhitelistedNumberPlates (params) {
        return UrlTo.getApi('api/wallet-corporate-plates-whitelist/search-by-plate', params)
    },
    apiDeleteCorporateWhitelistedNumberPlate (params) {
        return UrlTo.getApi('api/wallet-corporate-plates-whitelist/delete-number-plate', params)
    },
    apiDeleteAllCorporateWhitelistedNumberPlates (params) {
        return UrlTo.getApi('api/wallet-corporate-plates-whitelist/delete-all', params)
    },
    apiCreateCorporateWhitelistedNumberPlate (params) {
        return UrlTo.getApi('api/wallet-corporate-plates-whitelist/add-number-plate', params)
    },
    apiGetAutoDepositTiers (params) {
        return UrlTo.getApi('api/deposit-tier/get-auto-deposit-tiers', params)
    },
    apiGetDepositTiers (params) {
        return UrlTo.getApi('api/deposit-tier/get-deposit-tiers', params)
    },
    apiGetWalletMovements (params) {
        return UrlTo.getApi('api/wallet/movements', params)
    },
}

export default Routes
