// be careful!
// generated file with Yii routes with webpack. Don't update lines here!
export const json = {
    "bra\/site\/index": {
        "original_route": "bra\/en-us",
        "route": "mobile-parking-payments\/bra\/en-us",
        "params": []
    },
    "site\/error": {
        "original_route": "bra\/en-us\/error",
        "route": "mobile-parking-payments\/bra\/en-us\/error",
        "params": []
    },
    "bra\/sitemap\/lang": {
        "original_route": "bra\/en-us\/sitemap.xml",
        "route": "mobile-parking-payments\/bra\/en-us\/sitemap.xml",
        "params": []
    },
    "site\/terms": {
        "original_route": "bra\/en-us\/legal-terms",
        "route": "mobile-parking-payments\/bra\/en-us\/legal-terms",
        "params": []
    },
    "site\/terms-cookies": {
        "original_route": "bra\/en-us\/privacy-policy-cookies",
        "route": "mobile-parking-payments\/bra\/en-us\/privacy-policy-cookies",
        "params": []
    },
    "account\/on-boarding": {
        "original_route": "bra\/en-us\/complete-profile",
        "route": "mobile-parking-payments\/bra\/en-us\/complete-profile",
        "params": []
    },
    "on-boarding\/redirect": {
        "original_route": "bra\/en-us\/on-boarding-ok",
        "route": "mobile-parking-payments\/bra\/en-us\/on-boarding-ok",
        "params": []
    },
    "user\/user\/create": {
        "original_route": "POST bra\/en-us\/user",
        "route": "mobile-parking-payments\/bra\/en-us\/user",
        "params": []
    },
    "user\/user\/update": {
        "original_route": "PUT bra\/en-us\/user\/<id:\\d+>",
        "route": "mobile-parking-payments\/bra\/en-us\/user\/:id",
        "params": [
            "id"
        ]
    },
    "user\/user\/update-basic-location": {
        "original_route": "PUT bra\/en-us\/user-basic\/<id:\\d+>",
        "route": "mobile-parking-payments\/bra\/en-us\/user-basic\/:id",
        "params": [
            "id"
        ]
    },
    "account\/logout": {
        "original_route": "bra\/en-us\/disconnect",
        "route": "mobile-parking-payments\/bra\/en-us\/disconnect",
        "params": []
    },
    "user\/account\/request-delete-user": {
        "original_route": "PUT bra\/en-us\/remove-user",
        "route": "mobile-parking-payments\/bra\/en-us\/remove-user",
        "params": []
    },
    "user\/account\/delete": {
        "original_route": "bra\/en-us\/remove-user\/<token>",
        "route": "mobile-parking-payments\/bra\/en-us\/remove-user\/:token",
        "params": [
            "token"
        ]
    },
    "account\/edit": {
        "original_route": "bra\/en-us\/my-account",
        "route": "mobile-parking-payments\/bra\/en-us\/my-account",
        "params": []
    },
    "account\/user": {
        "original_route": "bra\/en-us\/my-details",
        "route": "mobile-parking-payments\/bra\/en-us\/my-details",
        "params": []
    },
    "account\/security": {
        "original_route": "bra\/en-us\/security",
        "route": "mobile-parking-payments\/bra\/en-us\/security",
        "params": []
    },
    "user\/account\/social-login": {
        "original_route": "bra\/en-us\/account\/social-login\/<social_network_name>",
        "route": "mobile-parking-payments\/bra\/en-us\/account\/social-login\/:social_network_name",
        "params": [
            "social_network_name"
        ]
    },
    "user\/account\/password-reset": {
        "original_route": "POST bra\/en-us\/recover-password",
        "route": "mobile-parking-payments\/bra\/en-us\/recover-password",
        "params": []
    },
    "account\/request-password-reset": {
        "original_route": "bra\/en-us\/recover-password",
        "route": "mobile-parking-payments\/bra\/en-us\/recover-password",
        "params": []
    },
    "user\/account\/reset-password-update": {
        "original_route": "POST bra\/en-us\/change-password\/<token>",
        "route": "mobile-parking-payments\/bra\/en-us\/change-password\/:token",
        "params": [
            "token"
        ]
    },
    "user\/account\/reset-password": {
        "original_route": "bra\/en-us\/change-password\/<token>",
        "route": "mobile-parking-payments\/bra\/en-us\/change-password\/:token",
        "params": [
            "token"
        ]
    },
    "account\/validate": {
        "original_route": "bra\/en-us\/validate\/<token>",
        "route": "mobile-parking-payments\/bra\/en-us\/validate\/:token",
        "params": [
            "token"
        ]
    },
    "user\/account\/email-update-request": {
        "original_route": "bra\/en-us\/email-change-request",
        "route": "mobile-parking-payments\/bra\/en-us\/email-change-request",
        "params": []
    },
    "account\/update-email": {
        "original_route": "bra\/en-us\/change-email\/<token>\/<newEmail>",
        "route": "mobile-parking-payments\/bra\/en-us\/change-email\/:token\/:newEmail",
        "params": [
            "token",
            "newEmail"
        ]
    },
    "user\/account\/confirm": {
        "original_route": "bra\/en-us\/change-email-confirm\/<code>",
        "route": "mobile-parking-payments\/bra\/en-us\/change-email-confirm\/:code",
        "params": [
            "code"
        ]
    },
    "account\/login": {
        "original_route": "bra\/en-us\/connect",
        "route": "mobile-parking-payments\/bra\/en-us\/connect",
        "params": []
    },
    "account\/register": {
        "original_route": "bra\/en-us\/register",
        "route": "mobile-parking-payments\/bra\/en-us\/register",
        "params": []
    },
    "user\/user\/change-password": {
        "original_route": "bra\/en-us\/change-password",
        "route": "mobile-parking-payments\/bra\/en-us\/change-password",
        "params": []
    },
    "glovie\/preferences": {
        "original_route": "GET bra\/en-us\/preferences",
        "route": "mobile-parking-payments\/bra\/en-us\/preferences",
        "params": []
    },
    "user-preferences\/update": {
        "original_route": "PUT bra\/en-us\/preferences\/<id:\\d+>",
        "route": "mobile-parking-payments\/bra\/en-us\/preferences\/:id",
        "params": [
            "id"
        ]
    },
    "glovie\/privacy": {
        "original_route": "GET bra\/en-us\/privacy",
        "route": "mobile-parking-payments\/bra\/en-us\/privacy",
        "params": []
    },
    "user\/privacy\/create-update": {
        "original_route": "PUT bra\/en-us\/privacy",
        "route": "mobile-parking-payments\/bra\/en-us\/privacy",
        "params": []
    },
    "user\/account\/unsubscribe-from-commercials": {
        "original_route": "bra\/en-us\/unsubscribe\/<email>",
        "route": "mobile-parking-payments\/bra\/en-us\/unsubscribe\/:email",
        "params": [
            "email"
        ]
    },
    "wallet\/list": {
        "original_route": "GET bra\/en-us\/wallet\/list",
        "route": "mobile-parking-payments\/bra\/en-us\/wallet\/list",
        "params": []
    },
    "wallet\/payment-method": {
        "original_route": "bra\/en-us\/wallet\/<idWallet:\\d+>\/payment-method",
        "route": "mobile-parking-payments\/bra\/en-us\/wallet\/:idWallet\/payment-method",
        "params": [
            "idWallet"
        ]
    },
    "wallet\/data": {
        "original_route": "bra\/en-us\/wallet\/<idWallet:\\d+>\/data",
        "route": "mobile-parking-payments\/bra\/en-us\/wallet\/:idWallet\/data",
        "params": [
            "idWallet"
        ]
    },
    "wallet\/beneficiaries": {
        "original_route": "bra\/en-us\/wallet\/<idWallet:\\d+>\/beneficiaries",
        "route": "mobile-parking-payments\/bra\/en-us\/wallet\/:idWallet\/beneficiaries",
        "params": [
            "idWallet"
        ]
    },
    "wallet\/my-wallet": {
        "original_route": "bra\/en-us\/wallet",
        "route": "mobile-parking-payments\/bra\/en-us\/wallet",
        "params": []
    },
    "wallet\/show": {
        "original_route": "bra\/en-us\/wallet\/<idWallet:\\d+>\/show",
        "route": "mobile-parking-payments\/bra\/en-us\/wallet\/:idWallet\/show",
        "params": [
            "idWallet"
        ]
    },
    "glovie\/wallet": {
        "original_route": "bra\/en-us\/wallets",
        "route": "mobile-parking-payments\/bra\/en-us\/wallets",
        "params": []
    },
    "wallet\/pay-debt": {
        "original_route": "PUT bra\/en-us\/wallet\/pay-debt",
        "route": "mobile-parking-payments\/bra\/en-us\/wallet\/pay-debt",
        "params": []
    },
    "wallet\/create-deposit": {
        "original_route": "POST bra\/en-us\/wallet\/top-up",
        "route": "mobile-parking-payments\/bra\/en-us\/wallet\/top-up",
        "params": []
    },
    "wallet\/associate-credit-card": {
        "original_route": "POST bra\/en-us\/wallet\/<idWallet:\\d+>\/associate-credit-card\/<idCreditCard:\\d+>",
        "route": "mobile-parking-payments\/bra\/en-us\/wallet\/:idWallet\/associate-credit-card\/:idCreditCard",
        "params": [
            "idWallet",
            "idCreditCard"
        ]
    },
    "wallet-preferences\/index": {
        "original_route": "GET bra\/en-us\/wallet-preferences",
        "route": "mobile-parking-payments\/bra\/en-us\/wallet-preferences",
        "params": []
    },
    "wallet-preferences\/update": {
        "original_route": "PUT bra\/en-us\/wallet-preferences\/<id:\\d+>",
        "route": "mobile-parking-payments\/bra\/en-us\/wallet-preferences\/:id",
        "params": [
            "id"
        ]
    },
    "credit-card\/new": {
        "original_route": "POST bra\/en-us\/credit-card",
        "route": "mobile-parking-payments\/bra\/en-us\/credit-card",
        "params": []
    },
    "credit-card\/delete": {
        "original_route": "DELETE bra\/en-us\/credit-card\/<id:\\d+>",
        "route": "mobile-parking-payments\/bra\/en-us\/credit-card\/:id",
        "params": [
            "id"
        ]
    },
    "credit-card\/create": {
        "original_route": "POST bra\/en-us\/wallet\/<idWallet:\\d+>\/credit-card",
        "route": "mobile-parking-payments\/bra\/en-us\/wallet\/:idWallet\/credit-card",
        "params": [
            "idWallet"
        ]
    },
    "credit-card\/list-by-country": {
        "original_route": "GET bra\/en-us\/credit-card\/list\/<countryId:\\d+>",
        "route": "mobile-parking-payments\/bra\/en-us\/credit-card\/list\/:countryId",
        "params": [
            "countryId"
        ]
    },
    "glovie\/vehicles": {
        "original_route": "bra\/en-us\/my-cars",
        "route": "mobile-parking-payments\/bra\/en-us\/my-cars",
        "params": []
    },
    "glovie\/vehicles-page": {
        "original_route": "bra\/en-us\/my-cars\/<page:\\d+>",
        "route": "mobile-parking-payments\/bra\/en-us\/my-cars\/:page",
        "params": [
            "page"
        ]
    },
    "vehicle-brand\/vehicle-models": {
        "original_route": "GET bra\/en-us\/vehicle-brand\/<brandId:\\d+>\/models",
        "route": "mobile-parking-payments\/bra\/en-us\/vehicle-brand\/:brandId\/models",
        "params": [
            "brandId"
        ]
    },
    "glovie\/trailers": {
        "original_route": "bra\/en-us\/my-trailers",
        "route": "mobile-parking-payments\/bra\/en-us\/my-trailers",
        "params": []
    },
    "glovie\/trailers-page": {
        "original_route": "bra\/en-us\/my-trailers\/<page:\\d+>",
        "route": "mobile-parking-payments\/bra\/en-us\/my-trailers\/:page",
        "params": [
            "page"
        ]
    },
    "vehicle\/index": {
        "original_route": "GET bra\/en-us\/vehicle",
        "route": "mobile-parking-payments\/bra\/en-us\/vehicle",
        "params": []
    },
    "vehicle\/view": {
        "original_route": "GET bra\/en-us\/vehicle\/<vehicleid:\\d+>",
        "route": "mobile-parking-payments\/bra\/en-us\/vehicle\/:vehicleid",
        "params": [
            "vehicleid"
        ]
    },
    "vehicle\/delete": {
        "original_route": "DELETE bra\/en-us\/vehicle\/<vehicleId:\\d+>",
        "route": "mobile-parking-payments\/bra\/en-us\/vehicle\/:vehicleId",
        "params": [
            "vehicleId"
        ]
    },
    "vehicle\/create": {
        "original_route": "POST bra\/en-us\/vehicle",
        "route": "mobile-parking-payments\/bra\/en-us\/vehicle",
        "params": []
    },
    "vehicle\/update": {
        "original_route": "PUT bra\/en-us\/vehicle\/<vehicleId:\\d+>",
        "route": "mobile-parking-payments\/bra\/en-us\/vehicle\/:vehicleId",
        "params": [
            "vehicleId"
        ]
    },
    "user-preferences\/auto-deposit": {
        "original_route": "bra\/en-us\/auto-deposit",
        "route": "mobile-parking-payments\/bra\/en-us\/auto-deposit",
        "params": []
    },
    "invoice\/pdf": {
        "original_route": "GET bra\/en-us\/billing\/invoices\/<invoiceUid>.pdf",
        "route": "mobile-parking-payments\/bra\/en-us\/billing\/invoices\/:invoiceUid.pdf",
        "params": [
            "invoiceUid"
        ]
    },
    "invoice\/xlsx": {
        "original_route": "GET bra\/en-us\/billing\/invoices\/<invoiceUid>.xlsx",
        "route": "mobile-parking-payments\/bra\/en-us\/billing\/invoices\/:invoiceUid.xlsx",
        "params": [
            "invoiceUid"
        ]
    },
    "invoice\/view-xlsx": {
        "original_route": "GET bra\/en-us\/download-billing\/<invoiceUid>.xlsx",
        "route": "mobile-parking-payments\/bra\/en-us\/download-billing\/:invoiceUid.xlsx",
        "params": [
            "invoiceUid"
        ]
    },
    "invoice\/list": {
        "original_route": "GET bra\/en-us\/billing\/invoices",
        "route": "mobile-parking-payments\/bra\/en-us\/billing\/invoices",
        "params": []
    },
    "invoice\/page": {
        "original_route": "GET bra\/en-us\/billing\/invoices\/<page:\\d+>",
        "route": "mobile-parking-payments\/bra\/en-us\/billing\/invoices\/:page",
        "params": [
            "page"
        ]
    },
    "invoice\/update": {
        "original_route": "PUT bra\/en-us\/billing\/data",
        "route": "mobile-parking-payments\/bra\/en-us\/billing\/data",
        "params": []
    },
    "kiosk\/reset-password": {
        "original_route": "bra\/en-us\/kiosko\/reset-password\/<token>",
        "route": "mobile-parking-payments\/bra\/en-us\/kiosko\/reset-password\/:token",
        "params": [
            "token"
        ]
    },
    "ser-ticket\/show-detail": {
        "original_route": "GET bra\/en-us\/ticket\/<idSERTicket:\\w+>\/detail",
        "route": "mobile-parking-payments\/bra\/en-us\/ticket\/:idSERTicket\/detail",
        "params": [
            "idSERTicket"
        ]
    },
    "ser-ticket\/send-email": {
        "original_route": "GET bra\/en-us\/ticket\/<idSERTicket:\\w+>\/send-email",
        "route": "mobile-parking-payments\/bra\/en-us\/ticket\/:idSERTicket\/send-email",
        "params": [
            "idSERTicket"
        ]
    },
    "glovie\/list-actives": {
        "original_route": "GET bra\/en-us\/guantera\/actuales",
        "route": "mobile-parking-payments\/bra\/en-us\/guantera\/actuales",
        "params": []
    },
    "glovie\/list-inactives": {
        "original_route": "GET bra\/en-us\/guantera\/pasados\/<page:\\d+>",
        "route": "mobile-parking-payments\/bra\/en-us\/guantera\/pasados\/:page",
        "params": [
            "page"
        ]
    },
    "ser-ticket\/download-pdf": {
        "original_route": "GET bra\/en-us\/ticket\/<idSERTicket:\\w+>.pdf",
        "route": "mobile-parking-payments\/bra\/en-us\/ticket\/:idSERTicket.pdf",
        "params": [
            "idSERTicket"
        ]
    },
    "ser-ticket\/create": {
        "original_route": "POST bra\/en-us\/ticket-be\/get-ticket",
        "route": "mobile-parking-payments\/bra\/en-us\/ticket-be\/get-ticket",
        "params": []
    },
    "ser-ticket\/unpark": {
        "original_route": "POST bra\/en-us\/ticket\/<id>\/unpark",
        "route": "mobile-parking-payments\/bra\/en-us\/ticket\/:id\/unpark",
        "params": [
            "id"
        ]
    },
    "ser-ticket\/check-unpark": {
        "original_route": "GET bra\/en-us\/ticket\/<id>\/unpark",
        "route": "mobile-parking-payments\/bra\/en-us\/ticket\/:id\/unpark",
        "params": [
            "id"
        ]
    },
    "ser-ticket\/show": {
        "original_route": "bra\/en-us\/view\/<id>\/ticket",
        "route": "mobile-parking-payments\/bra\/en-us\/view\/:id\/ticket",
        "params": [
            "id"
        ]
    },
    "ser-ticket\/new": {
        "original_route": "bra\/en-us\/new\/<id>\/ticket",
        "route": "mobile-parking-payments\/bra\/en-us\/new\/:id\/ticket",
        "params": [
            "id"
        ]
    },
    "ser-ticket\/create-and-pay-with-wallet": {
        "original_route": "POST bra\/en-us\/pay-ticket\/wallet\/<idWallet:\\d+>",
        "route": "mobile-parking-payments\/bra\/en-us\/pay-ticket\/wallet\/:idWallet",
        "params": [
            "idWallet"
        ]
    },
    "ser-ticket\/pay": {
        "original_route": "bra\/en-us\/park-in-<slug>\/pay-ticket",
        "route": "mobile-parking-payments\/bra\/en-us\/park-in-:slug\/pay-ticket",
        "params": [
            "slug"
        ]
    },
    "ser-steps\/index": {
        "original_route": "GET bra\/en-us\/steps\/zone\/<zoneId:\\w+>\/rate\/<rateId:\\w+>\/vehicle\/<vehicleId:\\d+>",
        "route": "mobile-parking-payments\/bra\/en-us\/steps\/zone\/:zoneId\/rate\/:rateId\/vehicle\/:vehicleId",
        "params": [
            "zoneId",
            "rateId",
            "vehicleId"
        ]
    },
    "town\/show": {
        "original_route": "bra\/en-us\/park-in-<slug>",
        "route": "mobile-parking-payments\/bra\/en-us\/park-in-:slug",
        "params": [
            "slug"
        ]
    },
    "poi\/show": {
        "original_route": "bra\/en-us\/park-in-<townSlug>\/parking-<poiSlug>",
        "route": "mobile-parking-payments\/bra\/en-us\/park-in-:townSlug\/parking-:poiSlug",
        "params": [
            "townSlug",
            "poiSlug"
        ]
    },
    "poi\/show-events": {
        "original_route": "bra\/en-us\/events-in-<poiId>\/<month:\\d+>",
        "route": "mobile-parking-payments\/bra\/en-us\/events-in-:poiId\/:month",
        "params": [
            "poiId",
            "month"
        ]
    },
    "wallet-corporate-plates-whitelist\/download": {
        "original_route": "bra\/en-us\/plates-whitelist\/<walletId:\\d+>\/download",
        "route": "mobile-parking-payments\/bra\/en-us\/plates-whitelist\/:walletId\/download",
        "params": [
            "walletId"
        ]
    },
    "api\/wallet-corporate\/download-file": {
        "original_route": "GET bra\/en-us\/wallets\/<walletId:\\d+>\/download-movements-csv\/<file>",
        "route": "mobile-parking-payments\/bra\/en-us\/wallets\/:walletId\/download-movements-csv\/:file",
        "params": [
            "walletId",
            "file"
        ]
    }
};